import React from 'react'
import '../styles/arrival.scss'
import { batteries } from '../data'
import { handleEnquiry } from '../enquiry'
import Title from './Shared/Title'

function Arrival() {
    return (
        <div className='mt-40'>
            <Title titleRed={"NEW"} titleWhite={"ARRIVALS"} />
            <div className="container mx-auto">
                <div className="grid grid-cols-12 gap-10 product-section">
                    {
                        batteries.filter(battery => battery.newArrival).slice(0, 3).map((battery, index) => (
                            <div className="col-span-4 product-first" key={index}>
                                <div className=' rounded-part'>
                                    <h3>{battery.category}</h3>
                                    <h4>{battery.series}</h4>
                                    <p>{battery.name}</p>
                                    <img className='battery' src={battery.image} alt="" />
                                    <h4>Capacity {battery.capacity}Ah {battery.time ? `@${battery.time}Hr` : ''}</h4>
                                    <h4>Warranty {battery.warranty} {battery.extendedWarranty ? `+ ${battery.extendedWarranty}` : ''} months</h4>
                                    <button className='px-4 py-3 text-white mt-7' style={{ backgroundColor: "#E53A10" }} onClick={() => handleEnquiry(battery)}   >Enquire Now</button>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default Arrival