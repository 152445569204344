import React from 'react'
import medal from '../../assets/Group 60.svg';

const Achievements = () => {
  const achievements = [
    {
      title: 'First Export House of Lead Acid Batteries',
      description: 'The Orion brand has a unique position in the market with competitive pricing, product quality and a round the clock after sales service(Toll Free No 180002700550). Orion became an ISO 9001(now ISO 9001-2015) company for maintaining strict quality norms.',
      description2: 'Towards the end of 2011, Orion entered the GCC markets with shipments to The Kingdom of Saudi Arabia. UAE and Oman, thus become the first export house of Lead Acid Batteries in SME Sector in Kerala'
    },
    {
      title: 'Years Of Experience In Business',
      description: 'At the time of establishment in 2002 the ORION had a very minimal budget and heavily dependent on manual labour and since then grown many folds and now boasts an automated, eco-friendly manufacturing plant and employs more than 50 people directly.',
      description2: 'There has been a considerable improvement in the growth process of the Company both in terms of quality& modern technology related production of Batteries and in terms of financial management as the present turnover is about 40Cores.'
    },
    {
      title: 'Awards',
      description: 'Orion bestowed with an award by the State Pollution Control Board for its sustained and substantial efforts to control pollution for the year 2011 . The promoter of ORION Battery,Mr.Babu MP, has been awarded the Best Entrepreneur Award of Kozhikode District (Twice) in the Year 2006-07 and 2010-11.',
      description2: 'On 7 Nov 2019, he has also been awarded Business Excellence Award 2019 in the category of Excellence in Energy Storage Solutions instituted by Media One TV.',
      description3: 'Considering his capabilities, the Govt. of Kerala has appointed him as Chairman of Institute Management Committee ITI Areacode and M/S Orion Battery is the Industry Partner of ITI.'
    }
  ]
  return (
    <div className='px-3 mt-32' >
      <div className='text-center'>
        <h4 className='font-koho text-[35px] font-normal' ><span className='text-red-600 '>OUR</span>  ACHIEVEMENTS</h4>
      </div>
      <div className='grid grid-cols-1'>
        {
          achievements.map((achievement, index) => (
            <div className='grid grid-cols-10 mt-24 ' key={index}>
              <div className='flex flex-col justify-center col-span-2 mb-5 lg:mb-0 lg:col-span-3'>
                <div className='bg-[#F6F6F6] rounded-full lg:w-64 lg:h-64 w-28 h-28  flex justify-center' >
                  <img src={medal} alt="" className='w-1/3 lg:w-1/2' />
                </div>
              </div>
              <div className='flex items-center col-span-7 lg:hidden'>
              <h4 className='sm:text-[25px] text-base font-ArgentumSans500 text-orange-600 sm:ml-0 ml-16'>{achievement.title}</h4>
              </div>
              <div className='flex justify-center col-span-10 lg:col-span-7 flx-col'>
                <div className='flex flex-col justify-center gap-4'>
                  <h4 className='text-[25px] font-ArgentumSans500 text-orange-600 hidden lg:block'>{achievement.title}</h4>
                  <p className='leading-[29.48px] font-ArgentumSans300'>{achievement.description}</p>
                  <p className='leading-[29.48px] font-ArgentumSans300'>{achievement.description2}</p>
                  <p className='leading-[29.48px] font-ArgentumSans300'>{achievement.description3}</p>
                </div>
              </div>
            </div>

          ))
        }
      </div>
    </div>
  )
}

export default Achievements