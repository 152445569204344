import React from "react";
import img1 from "../../assets/Certificates/image1.png";
import img3 from "../../assets/Certificates/image2.png";
import img4 from "../../assets/Certificates/image 18.png";
import img5 from "../../assets/Certificates/image 19.png";
import img6 from "../../assets/Certificates/image 20.png";
import { Masonry } from "@mui/lab";


const Certificate = () => {
  return (
    <div className="container flex flex-col w-full gap-10 px-4 mx-auto">
      <div className="">
        <div className="flex justify-center">
          <Masonry columns={2} spacing={10}>

            <div className="flex flex-col gap-8">
              <img src={img1} alt="" />
              <h4 className="text-center sm:text-3xl" style={{ color: "#E53A10" }}>
                BEST ENTREPRENUER AWARD 2010-11
              </h4>
            </div>

            <div className="flex flex-col gap-8">
              <img src={img3} alt="" />
              <h4 className="sm:text-3xl text-center ]" style={{ color: "#E53A10" }}>
                DAS CERTIFICATION
              </h4>
            </div>
            <div className="flex flex-col gap-8">
              <img src={img4} alt="" />
              <h4 className="sm:text-3xl text-center ]" style={{ color: "#E53A10" }}>
                OMAN CERTIFICATE
              </h4>
            </div>
            <div className="flex flex-col gap-8 relative 2xl:top-[90px] xl:top-[73px] lg:top-[92px] md:">
              <img src={img5} className="" />
              <h4 className="text-center sm:text-3xl " style={{ color: "#E53A10" }}>
                POLLUTION CONTROL AWARD
              </h4>
            </div>
          </Masonry>

        </div>

      </div>
      <div className="flex flex-col items-center justify-center gap-8">
        <img className="xl:w-auto " src={img6} alt="" />
        <h4 className="sm:text-3xl" style={{ color: "#E53A10" }}>
          CERTIFICATE OF APPRECIATION
        </h4>
      </div>
    </div>
  );
};

export default Certificate;
