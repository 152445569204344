import React from 'react'

const MissionHistory = () => {
  return (
    <div className='px-3 mt-16'>
      <div className='grid grid-cols-5'>
        <div className='flex flex-col justify-center col-span-5 gap-8 md:col-span-3'>
          <div className='flex items-center justify-between'>
            <h4 className='text-orange-600 font-ArgentumSans600 md:text-[35px] text-lg w-full'>What do we do</h4>
            <div className='flex justify-end md:hidden'>
              <img className='w-[40%] ' src={require('../../assets/Why 1.png')} alt="" />
            </div>
          </div>

          <p className='max-w-[859px] leading-10 sm:text-[23px] font-ArgentumSans300 text-base'>
            Orion mainly focus on improving the quality of product by addressing the various issues in the regular process and production times. Our objective is to enhance the life span of the products and ensuring better performance
          </p>
        </div>
        <div className='justify-center hidden col-span-2 md:flex '>
          <img className='w-[80%] sm:w-full' src={require('../../assets/Why 1.png')} alt="" />
        </div>
      </div>
      <div className='grid grid-cols-5 mt-32'>
        <div className='flex flex-col justify-center col-span-5 gap-8 md:col-span-3'>
          <div className='flex items-center justify-between'>
            <h4 className='text-orange-600 font-ArgentumSans600 md:text-[35px] text-lg'>Our Mission</h4>
            <div className='flex justify-end md:hidden'>
              <img className='w-[40%] ' src={require('../../assets/mission 1.png')} alt="" />
            </div>
          </div>
          <p className='max-w-[859px] leading-10 sm:text-[23px] font-ArgentumSans300 text-base'>
            Orion ideology is to strive for Customer Delight by providing and making available quality power store products and services, complying with the International norms and social commitments. With this in view we have unleashed our new mission of ORION ALL STORE.
          </p>
        </div>
        <div className='justify-center hidden col-span-2 md:flex '>
          <img src={require('../../assets/mission 1.png')} alt="" />
        </div>
      </div>
      <div className='grid grid-cols-5 mt-32'>
        <div className='flex flex-col justify-center col-span-5 gap-8 md:col-span-3'>
          <div className='flex items-center justify-between'>
            <h4 className='text-orange-600 font-ArgentumSans600 md:text-[35px] text-lg w-full'>Our History</h4>
            <div className='relative flex justify-end md:hidden'>
              <img className='w-[70%]' src={require('../../assets/circles 2.png')} alt="" />
              <img  src={require('../../assets/Orion battries 1.png')} alt="" className='absolute top-16 right-8 w-[40%]' />
            </div>
          </div>
          <p className='max-w-[859px] leading-10 sm:text-[23px] font-ArgentumSans300 text-base'>
            Orion Battery was established in the year 2002 at Poovattuparamaba, on Kozhikode - Mavoor Road, approximately 12 KM from City .Orion is a company manufacturing and marketing reliable and cost-effective Batteries ranging from 2.5 Ah to 300 Ah, Automotive, Tubular stationary and MC Battaries,under the Orion brand name.
          </p>
        </div>
        <div className='relative justify-center hidden col-span-2 md:flex '>
          <img src={require('../../assets/circles 2.png')} alt="" />
          <img src={require('../../assets/Orion battries 1.png')} alt="" className='absolute top-28' />
        </div>
      </div>
    </div>
  )
}

export default MissionHistory