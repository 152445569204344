import React, { useEffect } from 'react'
import ContactUsHero from '../components/contactUs/ContactUsHero'
import { useMediaQuery } from 'react-responsive';
import ContactUsContent from '../components/contactUs/ContactUsContent';
import Footer from '../components/Footer';
import MobileFooter from '../components/MobileFooter';
import ContactUsForm from '../components/contactUs/ContactUsForm';

const ContactUs = () => {
    const isMobile = useMediaQuery({ query: "(max-width: 1024px)" });
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <div>
            <ContactUsHero/>
            <ContactUsContent />
            <ContactUsForm/>
            {
                isMobile ?
                    <MobileFooter /> :
                    <Footer />
            }
        </div>
    )
}

export default ContactUs