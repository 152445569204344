import React, { useEffect } from 'react'
import Footer from '../components/Footer'
import Table from '../components/Distributers/Table'
import { distributers } from '../components/Distributers/Dist'
import { Allstore } from '../components/Distributers/Allstore'
import MobileFooter from '../components/MobileFooter'
import { useMediaQuery } from 'react-responsive'
import HeroV2 from '../components/Shared/HeroV2'
import distributorsImg from '../assets/ditributors.jpg'

const Distributors = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 1024px)" });
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div>
      <HeroV2 title={"Distributors"} background={distributorsImg} />
      {/* <Hero image={HeroImage} text={"Premium Automotive,<br>Tubular,Stationary & MC Batteries"} textWidth={657} /> */}
      <div className='flex items-center justify-center '>
        <Table data={distributers} headline={""} />
      </div>
      <div className='flex items-center justify-center sm:mb-40 sm:mt-40 mt-28 mb-28 '>
        <Table data={Allstore} headline={"All Store Network"} />
      </div>
      {isMobile ? <MobileFooter /> : <Footer />}
    </div>
  )
}

export default Distributors
