import React, { useState } from "react";
import "../styles/mobileheader.scss";

function MobileHeader() {

  const [main, setMain] = useState(false);
  const [sub, setSub] = useState(false);
  const handleMain = () => {
    setMain(!main);
  };
  const handleSub = () => {
    setSub(!sub);
  };
  return (
    <div className="relative">
      <div className="container px-3 mx-auto md:px-5 sm:px-4">
        <div className="mobileheader-section">
          <div className="mobileheader-logo">
            <a className="logo" href="/">
              <img src={require('../assets/orion.png')} alt=""   />
            </a>
          </div>
          <div
            className="flex-col px-2 mobileheader-menu "
            onClick={() => {
              handleMain();
              setSub(false);
            }}
          >
            {/* <img src="/images/menu.png" alt="" /> */}
            <span className="w-7">
              <hr className="border-2 border-white border-solid rounded" />
            </span>
            <span className="w-7">
              <hr className="border-2 border-white border-solid rounded" />
            </span>
            <span className="w-7">
              <hr className="border-2 border-white border-solid rounded" />
            </span>
          </div>
        </div>
      </div>
      {main && (
        <div
          className={`absolute  w-full ${!sub && "bg-white"}  z-40`}
          style={{ backgroundColor: sub && "#C8C8C8" }}
        >
          <ul className="flex flex-col justify-center px-6 py-7">
            <a href="/">
              <li
                className="pb-3 text-sm font-normal text-center border-b"
                style={{ borderColor: sub ? "#b0a7a7" : "#C8C8C8" }}
              >
                Home
              </li>
            </a>
            <a href="/products">
              <li
                className="py-3 text-sm font-normal text-center border-b"
                style={{ borderColor: sub ? "#b0a7a7" : "#C8C8C8" }}
              >
                Products
              </li>
            </a>
            <a href="/about-us">
              <li
                className="py-3 text-sm font-normal text-center border-b"
                style={{ borderColor: sub ? "#b0a7a7" : "#C8C8C8" }}
              >
                About Us
              </li>
            </a>
            <a href="/contact-us">
              <li
                className="py-3 pb-2 text-sm font-normal text-center border-b"
                style={{ borderColor: sub ? "#b0a7a7" : "#C8C8C8" }}
              >
                Contact Us
              </li>
            </a>
            {/* <a href="/">
              <li
                className="py-3 text-sm text-center border-b"
                style={{ borderColor: "#C8C8C8" }}
              >
                Blog
              </li>
            </a> */}
            <div className="relative" onClick={handleSub}>
              <li
                className="flex items-center justify-center gap-1 pt-3 text-sm font-normal text-center border- "
                style={{ borderColor: "#C8C8C8" }}
              >
                More
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="mt-[1px]"
                  width="10"
                  height="6"
                  viewBox="0 0 10 6"
                  fill="none"
                >
                  <path
                    d="M1 1L5.09221 5L9 1"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </li>
            </div>
          </ul>
        </div>
      )}
      {sub && (
        <div className="absolute top-[282px] bg-white w-full z-40">
          <ul className="flex flex-col justify-center px-6 py-4 ">
            <a href="/meet-the-captain">
              <li
                className="pb-3 text-sm font-normal text-center border-b"
                style={{ borderColor: "#C6C6C6" }}
              >
                Meet the Captain
              </li>
            </a>
            <a href="/projects">
              <li
                className="py-3 text-sm font-normal text-center border-b"
                style={{ borderColor: "#C6C6C6" }}
              >
                Our Projects
              </li>
            </a>
            <a href="/certifications">
              <li
                className="py-3 text-sm font-normal text-center border-b"
                style={{ borderColor: "#C6C6C6" }}
              >
                Awards and Certifications
              </li>
            </a>
            <a href="/distributors">
              <li
                className="pt-3 pb-3 text-sm font-normal text-center border-b "
                style={{ borderColor: "#C6C6C6" }}
              >
                Distributors
              </li>
            </a>
            <a href="/warranty">
              <li
                className="py-3 text-sm font-normal text-center border-b "
                style={{ borderColor: "#C6C6C6" }}
              >
                Online Warranty
              </li>
            </a>
            <a href="know-your-battery">
              <li
                className="py-3 text-sm font-normal text-center "
                style={{ borderColor: "#C6C6C6" }}
              >
                Know Your Battery
              </li>
            </a>
          </ul>
        </div>
      )}
    </div>
  );
}

export default MobileHeader;
