import React, { useEffect } from 'react'
import { useMediaQuery } from 'react-responsive';
import HeroImage from '../assets/products_hero.png'
import Hero from '../components/Shared/Hero';
import Product from '../components/products/Product';
import MobileFooter from '../components/MobileFooter';
import Footer from '../components/Footer';
import MobileProduct from '../components/products/MobileProduct';



const Products = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 1024px)" });
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
    return (
        <div className=' overflow-hidden'>
            <Hero image={HeroImage} text={"Powering The Heart, Of Your Home !"} textWidth={300} />
            <div className=' px-5'>
            {
                isMobile ?
                    <MobileProduct /> :
                    <Product />
            }
            {
                isMobile ?
                    <MobileFooter /> :
                    <Footer />
            }
            </div>
            
        </div>
    )
}

export default Products
