import React from "react";

const CapData = ({ value, label, style }) => {
  return (
    <div>
      <h2 className={`${style}   `}>
        <label style={{ color: "#E53A10" }}>{label}:</label> {value}
      </h2>
    </div>
  );
};

export default CapData;
