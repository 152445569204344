import React from 'react'
import '../styles/footer.scss'
import { Link, useNavigate } from 'react-router-dom'

function Footer() {
    const navigate = useNavigate()
    return (
        <div className=''>
            <div className='footer-section'>
                <div className="container grid grid-cols-8 mx-auto ">
                    <div className="flex flex-col h-full col-span-3 item-start">
                        <div className="w-52 " >
                            <img src={"/images/logo.png"} alt="" className='object-contain w-full h-full' onClick={() => navigate('/')} />
                        </div>
                        <div className="flex flex-col gap-2 pt-6">
                            <h5 className='text-[#E53A10] text-base font-normal font-ArgentumSans300  ' >Adress</h5>
                            <div className='flex flex-col gap-3'>

                            <p className='text-base leading-[150%]  text-white font-ArgentumSans300 w-[300px] xl:w-[364px]'>Orion Battery, Poovattumparamba P.O Peruvayal, Kozhikode, Kerala, 673008</p>
                            <p className='text-base leading-[150%]  text-white font-ArgentumSans300 w-[300px] xl:w-[364px]'>309/1A3, Puliyamarathupalayam, Varapatti, Coimbatore, Tamil Nadu, 641669</p>
                            </div>
                        </div>
                        <div className="flex mt-8 gap-7">
                            <h5 className='font-ArgentumSans300 text-[#E53A10] text-base font-normal ' >E-Mail</h5>
                            <p className='text-base font-normal text-white font-ArgentumSans300'>info@orioncells.com</p>
                        </div>
                    </div>
                    <div className="flex items-center justify-between col-span-5 gap-10 xl:gap-16 ">
                        <div className="">
                            <h4 className='text-[#E53A10] text-base xl:text-xl font-normal pb-9 '>Information</h4>
                            <div className='flex flex-col gap-4'>
                                <Link to="/about-us" className='text-white duration-300 font-ArgentumSans300 hover:scale-105'>About Us</Link>
                                <Link to="/products" className='text-white duration-300 font-ArgentumSans300 hover:scale-105'>Products</Link>
                                <Link to="/know-your-battery" className='text-white duration-300 font-ArgentumSans300 hover:scale-105'>Know your battery</Link>
                            </div>
                        </div>
                        <div className="footer-part-one">
                            <div className="">
                                <h4 className='text-[#E53A10] text-base xl:text-xl font-normal pb-9'>Extras</h4>
                                <div className='flex flex-col gap-4'>
                                    <Link to="/distributors" className='text-white duration-300 font-ArgentumSans300 hover:scale-105'>Distributors</Link>
                                    <Link to="/contact-us" className='text-white duration-300 font-ArgentumSans300 hover:scale-105'>Contact Us</Link>
                                    <Link to="/warranty" className='text-white duration-300 font-ArgentumSans300 hover:scale-105'>Warranty</Link>
                                </div>
                            </div>
                        </div>
                        <div className="footer-part-one">
                            <div className="part-one">
                                <h4 className='text-[#E53A10] text-base xl:text-xl font-normal pb-9'>Company Profile</h4>
                                <div className='flex flex-col gap-4'>
                                    <Link to="/meet-the-captain" className='text-white duration-300 font-ArgentumSans300 hover:scale-105' >Meet the captain</Link>
                                    <Link to="/projects" className='text-white duration-300 font-ArgentumSans300 hover:scale-105'>Our projects</Link>
                                    <Link to="/certifications" className='text-white duration-300 font-ArgentumSans300 hover:scale-105'>Award and certifications</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copy-rights">
                    <h5>Copyright   <span className="orange-text">ORION CELLS</span>, All rights reserved</h5>
                </div>
            </div>
        </div>

    )
}

export default Footer