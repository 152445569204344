import React, { useEffect } from 'react'
import Footer from '../components/Footer'
import { useMediaQuery } from 'react-responsive'
import MobileFooter from '../components/MobileFooter'
import Certificate from '../components/Certifications/Certificate'
import Mobilecertificate from '../components/Certifications/Mobilecertificate'
import HeroV2 from '../components/Shared/HeroV2'
import awards from "../assets/awards.png"

const Certification = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 1024px)" });
  const isMobileStrict = useMediaQuery({ query: "(max-width: 425px)" });
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className='flex flex-col gap-20'>
      <HeroV2 background={awards} title={"Awards and Certifications"} />
      {/* <Hero image={HeroImage} text={"Premium Automotive,<br>Tubular,Stationary & MC Batteries"} textWidth={657}/> */}
      {isMobileStrict ? <Mobilecertificate /> : <Certificate />}
      {isMobile ? <MobileFooter /> : <Footer />}
    </div>
  )
}

export default Certification
