
import React, { useEffect } from 'react'
import HeroImage from '../assets/batterytwo.png'
import Hero from '../components/Shared/Hero';
import BlogContents from '../components/blogs/BlogContents';
import MobileFooter from '../components/MobileFooter';
import Footer from '../components/Footer';
import { useMediaQuery } from 'react-responsive';
import HeroV2 from '../components/Shared/HeroV2';
import bgBlog from '../assets/blog-bg.jpg'

const Blog = () => {
    const isMobile = useMediaQuery({ query: "(max-width: 1024px)" });

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            {/* <Hero image={HeroImage} text={"Join the Conversation – Read Our Blog"} textWidth={522} /> */}
            <HeroV2 title="Join the Conversation" background={bgBlog} />
            <div className='w-full bg-white '>
                <div className='container w-full px-5 pb-20 mx-auto sm:pb-32'>
                    <BlogContents />
                </div>
                {
                    isMobile ?
                        <MobileFooter /> :
                        <Footer />
                }
            </div>

        </div>
    )
}

export default Blog