import React from 'react'
import '../../styles/sharedHero.scss'
import { useMediaQuery } from 'react-responsive';

const Hero = ({ image, text, textWidth }) => {
    const isMobile = useMediaQuery({ query: "(max-width: 1024px)" });
    return (
        <>

            {isMobile ? (<div className=''>
                <div className='absolute w-full'>
                    <img className='w-full h-full' src={require('../../assets/mobileEclipse.png')} alt="" />
                </div>
                <div className='relative flex items-center justify-center w-full sm:top-32 top-12 right-5 sm:right-10'>
                    <img className='object-contain w-[80%]' src={`${image}`} alt="" />
                </div>
                <div className='flex items-center justify-center w-full mt-24 sm:mt-36 '>
                    <h2 className='w-[20rem] sm:w-[80%] text-center text-white text-[20px] sm:text-3xl md:text-6xl font-medium font-argentumSans leading-[30.98px]'>
                        <div dangerouslySetInnerHTML={{ __html: text }} />
                    </h2>
                </div>
            </div>)
                :
                (<div className='relative mainHero '>
                    <div className='absolute round'>
                        <img className='w-full h-full' src={require('../../assets/round.png')} alt="" />
                    </div>
                    <div className='container relative flex items-center h-full gap-10 mx-auto '>
                        <div className='flex items-center w-[40%] h-full first-section'>
                            <img className='object-contain battery-img' src={`${image}`} alt="" />
                        </div>
                        <div className='w-1/2 h-full '>
                            <div className='flex items-center justify-center w-full h-full '>
                                <h2 className={`w-[${textWidth}px]  text-white  text-[30px] font-semibold font-ArgentumSans300 leading-[61.96px] xl:ml-0 ml-10 `}><div dangerouslySetInnerHTML={{ __html: text }} /></h2>
                            </div>
                        </div>

                    </div>

                </div>)}
        </>
    )
}

export default Hero