import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/swiper-bundle.css';


function MobileHero() {
  return (
    <div className="container-fluid">
      <Swiper
        spaceBetween={50}
        slidesPerView={1}
        modules={[Autoplay]}
        autoplay={{ delay: 3000 }}
      >
        <SwiperSlide>
          <div className='container mobilehero-content'>
            <h5>Powering Two - Wheelers</h5>
            <p>Powering Adventures</p>
          </div>
          <img
            className="d-block w-100 hero-img"
            src="/images/bg_bike.png"
            alt="First slide"
          />
        </SwiperSlide>

        <SwiperSlide>
          <div className="container mobilehero-content">
            <h5>Uninterrupted Power</h5>
            <p>For your Home</p>
          </div>
          <img
            className="d-block w-100 hero-img"
            src="/images/bg_home.png"
            alt="Second slide"
          />
        </SwiperSlide>

        <SwiperSlide>
          <div className="container mobilehero-content">
            <h5>Power Your Drive</h5>
            <p>Reliable, Powerful</p>
          </div>
          <img
            className="d-block w-100 hero-img"
            src="/images/bg_car.png"
            alt="Third slide"
          />
        </SwiperSlide>
      </Swiper>
    </div>
  );
}

export default MobileHero;
