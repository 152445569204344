import React, { useEffect } from 'react'
import Footer from '../components/Footer'
import InfoCard from '../components/Shared/InfoCard'
import { useMediaQuery } from 'react-responsive'
import MobileFooter from '../components/MobileFooter'
import { data } from '../components/Projects/text'
import HeroV2 from '../components/Shared/HeroV2'
import projects from '../assets/projects.jpg'

const Projects = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 1024px)" });
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div>
      <HeroV2 title={"Projects"} background={projects} />
      {data.map((value, index) => (
        <InfoCard key={index} heading={value?.heading} subHeading={value.subheading} text={value.text} id={value.id} />
      ))}
      {isMobile ? <MobileFooter /> : <Footer />}
    </div>
  )
}

export default Projects
