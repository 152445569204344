import React from "react";
import { useLocation } from "react-router-dom";

const InfoCard = ({ heading, text, subHeading, id }) => {
  const location = useLocation();
  const style1 = location.pathname === "/projects";
  const style2 = location.pathname === "/meet-the-captain";
  const lastElement = id === 6;
  //  {}
  return (
    <div
      className={`${
        style1
          ? `sm:mt-40  mt-14 lg:px-20  `
          : `${
              style2
                ? "sm:pb-40 pb-10 "
                : "lg:mt-10 xl:40 2xl:pb-60 lg:pb-20 md:pb-40 sm:pb-20 pb-20 lg:p-20 "
            }`
      } ${style1 && lastElement ? "sm:pb-40 pb-10" : ""} `}
    >
      <div
        className={`${
          style1
            ? `container mx-auto text-white flex flex-col sm:gap-[45px] gap-10 sm:p-0 px-[36px] `
            : `${
                style2
                  ? "container mx-auto text-white flex flex-col sm:gap-[40px] gap-9 sm:p-0 px-[36px]"
                  : "container mx-auto text-white flex flex-col sm:gap-[155px] gap-9 sm:p-0 px-[36px]"
              }`
        } `}
      >
        {heading && (
          <div>
            <h2
              className={`${
                style1
                  ? `text-center text-[17px] font-medium sm:text-[40px]`
                  : "text-center text-[17px] font-semibold sm:text-3xl "
              } `}
            >
              {heading}
            </h2>
          </div>
        )}
        {subHeading && (
          <div className=" lg:p-0 md:px-6">
            <h2
              className="text-left text-[17px] font-semibold sm:text-3xl font-ArgentumSans600 "
              style={{ color: "#E53A10" }}
            >
              {subHeading}
            </h2>
          </div>
        )}
        {text && (
          <div className=" lg:p-0 md:px-6">
            <p className="text-sm font-light sm:text-left 2xl:text-xl xl:text-xl md:text-xl sm:text-sm font-ArgentumSans300 ">
              {" "}
              <div dangerouslySetInnerHTML={{ __html: text }} />
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default InfoCard;
