import React from 'react'
import { blogData } from './data'

const BlogContents = () => {
    return (
        <div className='flex flex-col '>
            {
                blogData.map((blog, index) => (
                    <div className='flex flex-col justify-center gap-6 mt-28' key={index}>
                        <div className='flex flex-col gap-5'>
                            <h3 className=' text-lg lg:text-[50px] font-ArgentumSans600'>{blog.title}</h3>
                            <h3 className='text-lg text-[#5e5e5e] lg:text-[40px] font-ArgentumSans500'>{blog.subTitle}</h3>
                        </div>
                        {/* <h3 className='text-lg font-semibold font-argentumSans'>Powering the Future: Sustainable Battery Technology</h3> */}
                        <div className='w-full rounded-lg '>
                            <img className='w-full rounded-lg' src={blog.image} alt="" />
                        </div>
                        <div className='flex flex-col gap-6 mt-4'>
                            <p className='text-black text-sm sm:text-[23px] font-light font-argentumSans300 leading-10 w-[90%]  '>{blog.paragraph1}</p>
                            <p className='text-black text-sm sm:text-[23px] font-light font-argentumSans300 leading-10 w-[90%]  '>{blog.paragraph2}</p>
                            <p className='text-black text-sm sm:text-[23px] font-light font-argentumSans300 leading-10 w-[90%]  '>{blog.paragraph3}</p>
                            {/* <p className='text-orange-600 text-base sm:text-[23px] font-light font-argentumSans leading-10 text-center'>Read More</p> */}
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default BlogContents