import React from 'react'
import Title from '../Shared/Title'

const ContactUsForm = () => {
    return (
        <div className='pb-[7%] '>
            <div className='hidden lg:block'>
            <Title titleRed={"GET IN"} titleWhite={"TOUCH"} />
            </div>

            <div className='flex justify-center w-full mt-[7%]'>

                <form className='flex justify-center flex-col md:flex-row xl:gap-40 md:gap-20 gap-5 w-full items-center md:items-start '>
                    <div className='flex flex-col md:w-[40%] w-[80%] gap-10 md:items-end items-center'>
                        <div className='xl:w-[60%] w-[80%] flex flex-col gap-3'>
                            <h3 className='text-white'>Name</h3>
                            <input type="text" placeholder='Name' className='text-white w-full h-12 flex justify-center items-center p-5 ' style={{ backgroundColor: '#141414' }} />
                        </div>
                        <div className='xl:w-[60%] w-[80%] flex flex-col gap-3'>
                            <h3 className='text-white'>Your Email</h3>
                            <input type="text" placeholder='E-Mail' className='text-white w-full h-12 p-5 ' style={{ backgroundColor: '#141414' }} />
                        </div>
                        <div className='xl:w-[60%] w-[80%] flex flex-col gap-3'>
                            <h3 className='text-white'>Phone Number</h3>
                            <input type="text" placeholder='Mobile Number' className='text-white w-full h-12 p-5 ' style={{ backgroundColor: '#141414' }} />
                        </div>

                    </div>
                    <div className='flex flex-col md:w-[40%] w-[80%] md:items-start items-center justify-between gap-5 md:gap-0 h-full'>
                        <div className='xl:w-[60%] w-[80%] flex flex-col gap-3'>
                            <h3 className='text-white'>Your Message</h3>
                            <textarea type="text" placeholder='Message' className='text-white w-full h-40 p-5 ' style={{ backgroundColor: '#141414' }} />
                        </div>

                        <div className='xl:w-[60%] w-[80%] flex justify-center'>
                            <button className='text-white px-8 py-2 rounded-none' style={{ backgroundColor: ' #E53A10' }}>SEND</button>

                        </div>
                    </div>

                </form>
            </div>
        </div>

    )
}

export default ContactUsForm