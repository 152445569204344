import React, { useEffect, useState } from 'react'
import Hero from '../components/Hero'
import '../styles/home.scss'
import Footer from '../components/Footer'
import AboutUs from '../components/AboutUs'
import Arrival from '../components/Arrival'
import Categories from '../components/Categories'
import { useMediaQuery } from 'react-responsive'
import MobileHeader from '../components/MobileHeader'
import MobileArrival from '../components/MobileArrival'
import MobileCategory from '../components/MobileCategory'
import MobileAbout from '../components/MobileAbout'
import MobileFooter from '../components/MobileFooter'
import HomeHero from '../components/home/HomeHero'
import MobileHeroHome from '../components/home/MobileHeroHome'

function Home() {
  const [openNavigation, setOpenNavigation] = useState(true)
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1025px)'
  })
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const isTab = useMediaQuery({ query: "(min-width: 769px) and (max-width: 1024px)" });
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div style={openNavigation ? { overflowY: 'hidden' } : null}>
      {
        (isDesktopOrLaptop || isBigScreen) && (
          <>
            <>
              {/* <Header /> */}
              <Hero />
              <HomeHero />
              {/* <Deals /> */}
              <Categories />
              <Arrival />
              {/* <Products /> */}
              <AboutUs />
              <Footer />
            </>
          </>
        )
      }
      {
        isTab && (
          <>
            <MobileHeroHome />
            {/* <MobileHeader /> */}
            {/* <MobileHeader/> */}
            {/* <MobileDeals /> */}
            <Categories />
            <MobileArrival />
            {/* <MobileProduct /> */}
            <MobileAbout />
            <MobileFooter />

          </>
        )
      }
      {
        isMobile && (
          <>
            {/* <MobileHeader /> */}
            <MobileHeroHome />
            {/* <MobileDeals /> */}
            <Categories />
            <MobileArrival />
            {/* <MobileProduct /> */}
            <MobileAbout />
            <MobileFooter />
          </>
        )
      }

    </div>

  )
}

export default Home