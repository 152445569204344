import React from "react";
import "../../styles/sharedHero.scss";
import { useMediaQuery } from "react-responsive";
import HeroImage from "../../assets/Ellipse 3.png";
import Hero2 from "../../assets/image 15.png";
import Hero3 from "../../assets/image.16.png";
import { Captaindata } from "./Data";
import CapData from "./CapData";

const HeroTwo = ({ image, text, textWidth }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 1024px)" });
  return (
    <>
      {isMobile ? (
        <div className="">
          <div className="absolute w-full">
            <img
              className="w-full h-full"
              src={require("../../assets/mobileEclipse.png")}
              alt=""
            />
          </div>
          <div className="relative flex items-center justify-center w-full sm:top-32 top-12  ">
            <img
              className="object-contain w-[80%] sm:ml-14 ml-5"
              src={`${Hero3}`}
              alt=""
            />
          </div>
          <div className="flex items-center justify-center w-full mt-24 sm:mt-60">
            <h2 className="w-[20rem] sm:w-[80%] text-left text-white text-[14px] sm:text-3xl md:text-6xl font-medium 2xl:font-semibold  font-argentumSans flex flex-col gap-4 ">
              {Captaindata.map((data, key) => (
                <>
                  <CapData
                    value={data.name}
                    label={"Name"}
                    style={
                      "text-white sm:text-xl  text-[14px] font-light font-ArgentumSans300 leading-[30.98px] xl:ml-0 md:ml-0 ml-10"
                    }
                  />
                  <CapData
                    value={data.father}
                    label={"Father's Name"}
                    style={
                      "text-white sm:text-xl  text-[14px] font-light font-ArgentumSans300 leading-[30.98px] xl:ml-0 md:ml-0 ml-10"
                    }
                  />
                  <CapData
                    value={data.dob}
                    label={"Date of Birth"}
                    style={
                      "text-white sm:text-xl  text-[14px] font-light font-ArgentumSans300 leading-[30.98px] xl:ml-0 md:ml-0 ml-10"
                    }
                  />
                  <CapData
                    value={data.education}
                    label={"Education"}
                    style={
                      "text-white sm:text-xl  text-[14px] font-light font-ArgentumSans300 leading-[30.98px] xl:ml-0 md:ml-0 ml-10"
                    }
                  />
                  {/* <h4 className="text-white">calicut university</h4> */}
                </>
              ))}
            </h2>
          </div>
        </div>
      ) : (
        <div className="relative ">
          <div className="relative top-[45px]">
            <h4 className="text-center text-white text-3xl">
              Meet The Captain
            </h4>
          </div>
          <div className="absolute round  right-0 ">
            <img className="w-70  float-right" src={HeroImage} alt="" />
          </div>
          <div className="relative  flex items-center  h-full  translate-y-1/2  container mx-auto">
            <div className="w-1/2 h-full ">
              <div className="  flex     flex-col justify-center w-full gap-[24px] h-full ">
                {Captaindata.map((data, key) => (
                  <>
                    <CapData
                      value={data.name}
                      label={"Name"}
                      style={
                        "text-white sm:text-xl  text-[30px] font-light font-ArgentumSans300 leading-[61.96px] xl:ml-0 ml-0"
                      }
                    />
                    <CapData
                      value={data.father}
                      label={"Father's Name"}
                      style={
                        "text-white sm:text-xl  text-[30px] font-light font-ArgentumSans300 leading-[61.96px] xl:ml-0 ml-0"
                      }
                    />
                    <CapData
                      value={data.dob}
                      label={"Date of Birth"}
                      style={
                        "text-white sm:text-xl  text-[30px] font-light font-ArgentumSans300 leading-[61.96px] xl:ml-0 ml-0"
                      }
                    />
                    <CapData
                      value={data.education}
                      label={"Education"}
                      style={
                        "text-white sm:text-xl  text-[30px] font-light font-ArgentumSans300 leading-[61.96px] xl:ml-0 ml-0"
                      }
                    />
                    {/* <h4 className="text-white">calicut university</h4> */}
                  </>
                ))}
              </div>
            </div>
            <div className=" w-1/2  h-full flex items-center justify-center">
              <img className="object-contain " src={`${Hero2}`} alt="" />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default HeroTwo;
