import React, { useEffect } from 'react'
import OurMantra from '../components/aboutUs/OurMantra'
import MantraList from '../components/aboutUs/MantraList'
import MissionHistory from '../components/aboutUs/MissionHistory'
import Achievements from '../components/aboutUs/Achievements'
import Testimonials from '../components/aboutUs/Testimonials'
import Hero from '../components/Hero'
import { useMediaQuery } from 'react-responsive'
import MobileFooter from '../components/MobileFooter'
import Footer from '../components/Footer'


const AboutUs = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 1024px)" });
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="">

      {/* hero */}
      {/* <Hero /> */}
      {/* contents */}
      <div className="bg-white ">
        <div className="container gap-10 px-2 py-10 mx-auto ">
          <OurMantra />
          <MantraList />
          <MissionHistory />
          <Achievements />
          <Testimonials />
        </div>

      </div>
      {/* footer */}
      {
        isMobile ?
          <MobileFooter /> :
          <Footer />
      }
    </div>
  );
};

export default AboutUs;
