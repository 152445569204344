import React from 'react'
import '../styles/mobileabout.scss'

function MobileAbout() {
  return (
    <div className="">
      <div className="container mx-auto">
        <div className="mob-about">
          <div className="mob-about-section">
            <h3>ABOUT<span>US</span></h3>
            <p className='mob-para'>Orion is a company manufacturing and marketing reliable and cost-effective Batteries ranging from 2.5 Ah to 300 Ah, Automotive, Tubular stationary and MC Battaries,under the Orion brand name</p>
            <p className='mob-para-second'>The Orion brand has a unique position in the market with competitive pricing, product quality and a round the clock after sales service</p>
            <div className="mobcustomer-service">
              <img src="/images/customer.png" alt="" />
              <h3>Customer Care :</h3>
              <p>+91586232151</p>
            </div>
          </div>
        </div>
        <div className="mobform">
          <div className="mobform-head">
            <h3>QUICK <span>CONTACT</span> </h3>
          </div>
          <div className="mobform-content">
            <form >
              <input type="text" placeholder='Name' className='w-full' />
              <input type="text" placeholder='E-Mail' className='w-full' />
              <input type="text" placeholder='Mobile Number' className='w-full' />
              <input className='w-full message' type="text" placeholder='Message'  />
              <button>SEND</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MobileAbout