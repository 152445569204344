
// din series
import DIN44 from './assets/din-series/60B DIN 44.png';
import DIN55 from './assets/din-series/60B DIN 55.png';
import DIN66 from './assets/din-series/60B DIN 66.png';

// heavy duty series
import OB100 from './assets/heavy-duty/60B N100.png';
import OB130 from './assets/heavy-duty/60B N130.png';
import OB150 from './assets/heavy-duty/60B N150.png';
import OB200 from './assets/heavy-duty/60B N200.png';

// premium series c10
import EL110 from './assets/premium/EL 110.png';
import EL160 from './assets/premium/EL 160.png';
import EL200 from './assets/premium/EL 200.png';

// premium series c20
import OEL1000 from './assets/premium/OEL1000.png';
import OEL1200 from './assets/premium/OEL1200.png';
import OEL1500 from './assets/premium/OEL1500.png';
import OEL2000 from './assets/premium/OEL2000.png';

// rigel series c10
import EL110R from './assets/rigel-series/60 B EL 110R.png';
import EL160R from './assets/rigel-series/60 B EL 160R.png';
import EL200R from './assets/rigel-series/60 B EL 200R.png';
import EL225R from './assets/rigel-series/60 B EL 225R.png';

// rigel series c20
import OEL1000R from './assets/rigel-series/OEL 1000R.png';
import OEL1200R from './assets/rigel-series/OEL 1200R.png';
import OEL1500R from './assets/rigel-series/OEL 1500R.png';
import OEL2000R from './assets/rigel-series/OEL 2000R.png';

// pt series c10
import PT400 from './assets/pt-series/PT 400.png';
import PT600 from './assets/pt-series/PT 600.png';
import PT800 from './assets/pt-series/PT 800.png';

// calcium series
import CA400R from './assets/calcium-series/60 B 400R.png';
import BH20L from './assets/calcium-series/60 BH 20L.png';
import OB75D26L from './assets/calcium-series/60 B 75 D 26 L.png';
import OB75D26R from './assets/calcium-series/60 B 75 D 26 R.png';
import OB80D26L from './assets/calcium-series/60 B 80 D 26 L.png';
import OB80D26R from './assets/calcium-series/60 B 80 D 26 R.png';
import OB90D31L from './assets/calcium-series/60 B 90 D 31 L.png';
import OB90D31R from './assets/calcium-series/60 B 90 D 31 R.png';





export const cateories = [
  {
    id: 1,
    name: 'Motor Cycle',
    slug: 'motorcycle',

  },
  {
    id: 2,
    name: 'Automotive',
    slug: 'automotive',

  },
  {
    id: 3,
    name: 'Tubular',
    slug: 'tubular',
  }
]

export const series = [
  {
    id: 1,
    name: 'DIN Series',
    slug: 'din_series',
    category: 'motorcycle',

  },
  {
    id: 2,
    name: 'Heavy Duty Series',
    slug: 'heavy_duty_series',
    category: 'motorcycle'

  },
  {
    id: 3,
    name: 'Premium Series C10',
    slug: 'premium_series_c10',


  },
  {
    id: 4,
    name: 'Premium Series C20',
    slug: 'premium_series_c20',

  },
  {
    id: 5,
    name: 'Rigel Series C10',
    slug: 'rigel_series_c10',

  },
  {
    id: 6,
    name: 'Rigel Series C20',
    slug: 'rigel_series_c20',
  },
  {
    id: 7,
    name: 'PT Series C10',
    slug: 'pt_series_c10',
  },
  {
    id: 8,
    name: 'Calcium Series',
    slug: 'calcium_series',
  }

]

export const batteries = [

  {
    id: 13,
    name: '60B EL 200',
    capacity: 200,
    time: 10,
    warranty: 60,
    image: EL200,
    series: 'Premium Series C10',
    category: 'tubular',
    newArrival: true
  },
  {
    id: 14,
    name: '60B EL 160',
    capacity: 160,
    time: 10,
    warranty: 60,
    image: EL160,
    series: 'Premium Series C10',
    category: 'tubular',
    newArrival: true
  },
  {
    id: 15,
    name: '60B EL 110',
    capacity: 110,
    time: 10,
    warranty: 60,
    image: EL110,
    series: 'Premium Series C10',
    category: 'tubular',
    newArrival: true

  },
  {
    id: 16,
    name: 'OEL 2000',
    capacity: 200,
    time: 20,
    warranty: 48,
    image: OEL2000,
    series: 'Premium Series C20',
    category: 'tubular'
  },
  {
    id: 17,
    name: 'OEL 1500',
    capacity: 150,
    time: 20,
    warranty: 48,
    image: OEL1500,
    series: 'Premium Series C20',
    category: 'tubular',
    hotDeal: true,
  },
  {
    id: 18,
    name: 'OEL 1200',
    capacity: 120,
    time: 20,
    warranty: 48,
    image: OEL1200,
    series: 'Premium Series C20',
    category: 'tubular',
    hotDeal: true
  },
  {
    id: 19,
    name: 'OEL 1000',
    capacity: 100,
    time: 20,
    warranty: 48,
    image: OEL1000,
    series: 'Premium Series C20',
    category: 'tubular',
  },
  {
    id: 36,
    name: '60B EL 225 R',
    capacity: 225,
    time: 10,
    warranty: 36,
    image: EL225R,
    series: 'Rigel Series C10',
    category: 'tubular'
  },

  {
    id: 20,
    name: '60B EL 200 R',
    capacity: 200,
    time: 10,
    warranty: 36,
    image: EL200R,
    series: 'Rigel Series C10',
    category: 'tubular'
  },
  {
    id: 21,
    name: '60B EL 160 R',
    capacity: 160,
    time: 10,
    warranty: 36,
    image: EL160R,
    series: 'Rigel Series C10',
    category: 'tubular'

  },
  {
    id: 22,
    name: '60B EL 110 R',
    capacity: 110,
    time: 10,
    warranty: 36,
    image: EL110R,
    series: 'Rigel Series C10',
    category: 'tubular',
    newArrival: true

  },
  {
    id: 23,
    name: 'OEL 2000 R',
    capacity: 200,
    time: 20,
    warranty: 36,
    image: OEL2000R,
    series: 'Rigel Series C20',
    category: 'tubular'
  },
  {
    id: 24,
    name: 'OEL 1500 R',
    capacity: 150,
    time: 20,
    warranty: 36,
    image: OEL1500R,
    series: 'Rigel Series C20',
    category: 'tubular'

  },
  {
    id: 25,
    name: 'OEL 1200 R',
    capacity: 120,
    time: 20,
    warranty: 36,
    image: OEL1200R,
    series: 'Rigel Series C20',
    category: 'tubular'
  },
  {
    id: 26,
    name: 'OEL 1000 R',
    capacity: 100,
    time: 20,
    warranty: 36,
    image: OEL1000R,
    series: 'Rigel Series C20',
    category: 'tubular'

  },
  {
    id: 27,
    name: 'PT 400',
    capacity: 40,
    time: 10,
    warranty: 36,
    image: PT400,
    series: 'PT Series C10',
    category: 'tubular',

  },
  {
    id: 28,
    name: 'PT 600',
    capacity: 60,
    time: 10,
    warranty: 36,
    image: PT600,
    series: 'PT Series C10',
    category: 'tubular'
  },
  {
    id: 29,
    name: 'PT 800',
    capacity: 80,
    time: 10,
    warranty: 36,
    image: PT800,
    series: 'PT Series C10',
    category: 'tubular'
  },
  {
    id: 37,
    name: '60B Ca400 R',
    capacity: 35,
    warranty: 18,
    image: CA400R,
    series: 'Calcium Series',
    category: 'automotive',

  },
  {
    id: 31,
    name: '60B BH 20 L',
    capacity: 38,
    warranty: 18,
    image: BH20L,
    series: 'Calcium Series',
    category: 'automotive'
  },
  // {
  //   id: 32,
  //   name: '60B B 24 L',
  //   capacity: 45,
  //   warranty: 18,
  //   image: image,
  //   series: 'Calcium Series',
  //   category: 'automotive'
  // },
  {
    id: 33,
    name: '60 B 75 D 26 L',
    capacity: 60,
    warranty: 18,
    image: OB75D26L,
    series: 'Calcium Series',
    category: 'automotive',
  },
  {
    id: 33,
    name: '60 B 75 D 26 R',
    capacity: 60,
    warranty: 18,
    image: OB75D26R,
    series: 'Calcium Series',
    category: 'automotive',
  },
  {
    id: 34,
    name: '60 B 80 D 26 L',
    capacity: 70,
    warranty: 18,
    image: OB80D26L,
    series: 'Calcium Series',
    category: 'automotive'
  },
  {
    id: 34,
    name: '60 B 80 D 26 R',
    capacity: 70,
    warranty: 18,
    image: OB80D26R,
    series: 'Calcium Series',
    category: 'automotive'
  },
  {
    id: 35,
    name: '60 B 90 D 31 L',
    capacity: 90,
    warranty: 18,
    image: OB90D31L,
    series: 'Calcium Series',
    category: 'automotive'
  },
  {
    id: 35,
    name: '60 B 90 D 31 R',
    capacity: 90,
    warranty: 18,
    image: OB90D31R,
    series: 'Calcium Series',
    category: 'automotive'
  },

  {
    id: 1,
    name: '60B 44 DIN',
    capacity: 44,
    warranty: 18,
    extendedWarranty: 0,
    image: DIN44,
    series: 'DIN Series',
    category: 'motorcycle',
    hotDeal: true
  },
  {
    id: 2,
    name: '60B 55 DIN',
    capacity: 55,
    warranty: 18,
    extendedWarranty: 0,
    image: DIN55,
    series: 'DIN Series',
    category: 'motorcycle',
  },
  {
    id: 3,
    name: '60B 66 DIN',
    capacity: 65,
    warranty: 18,
    extendedWarranty: 0,
    image: DIN66,
    series: 'DIN Series',
    category: 'motorcycle',
  },
  // {
  //   id: 4,
  //   name: '60B N 100',
  //   capacity: 88,
  //   warranty: 18,
  //   extendedWarranty: 6,
  //   image: image,
  //   series: 'Heavy Duty Series',
  //   category: 'motorcycle',
  // },
  {
    id: 4,
    name: '60B N 100',
    capacity: 100,
    warranty: 18,
    extendedWarranty: 6,
    image: OB100,
    series: 'Heavy Duty Series',
    category: 'motorcycle',
  },
  {
    id: 5,
    name: '60B N 130',
    capacity: 130,
    warranty: 18,
    extendedWarranty: 6,
    image: OB130,
    series: 'Heavy Duty Series',
    category: 'motorcycle',
    hotDeal: true
  },
  {
    id: 6,
    name: '60B N 150',
    capacity: 150,
    warranty: 18,
    extendedWarranty: 6,
    image: OB150,
    series: 'Heavy Duty Series',
    category: 'motorcycle'
  },
  {
    id: 7,
    name: '60B N 200',
    capacity: 200,
    warranty: 18,
    extendedWarranty: 6,
    image: OB200,
    series: 'Heavy Duty Series',
    category: 'motorcycle'
  },
  // {
  //   id: 8,
  //   name: '60B MF Z4',
  //   capacity: 4,
  //   warranty: 18,
  //   image: image,
  //   series: 'Heavy Duty Series',
  //   category: 'motorcycle'
  // },
  // {
  //   id: 9,
  //   name: '60B MF 5',
  //   capacity: 5,
  //   warranty: 18,
  //   image: image,
  //   series: 'Heavy Duty Series',
  //   category: 'motorcycle'
  // },
  // {
  //   id: 10,
  //   name: '60B MF Z5',
  //   capacity: 5,
  //   warranty: 18,
  //   image: image,
  //   series: 'Heavy Duty Series',
  //   category: 'motorcycle'
  // },
  // {
  //   id: 11,
  //   name: '60B MF 7',
  //   capacity: 7,
  //   warranty: 18,
  //   image: image,
  //   series: 'Heavy Duty Series',
  //   category: 'motorcycle'
  // },
  // {
  //   id: 12,
  //   name: '60B MF 9',
  //   capacity: 9,
  //   warranty: 18,
  //   image: image,
  //   series: 'Heavy Duty Series',
  //   category: 'motorcycle'
  // }

]