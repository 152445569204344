export const distributers = [
    {
        id: 1,
        distributers: "Battery Store",
        district: "Kasargod",
        address: "Nileswar, Kasargod",
        phone: "9400208524"
    },
    {
        id: 2,
        distributers: "Orion All Store",
        district: "Kozhikode",
        address: "Golf Link Road, Chevararmbalam",
        phone: "9961918666"
    },
    {
        id: 3,
        distributers: "Powerland Battery & Inverter",
        district: "Kozhikode",
        address: "Opp. City Petroleum, Kallachi, Vadakara",
        phone: "9895626689"
    },
    {
        id: 4,
        distributers: "Star One Agencies",
        district: "Malappuram",
        address: "PALLIPPADI, PANDALLUR, MALAPPURAM",
        phone: "9846208719"
    },
    {
        id: 5,
        distributers: "Chorotti Exide Shoppee",
        district: "Thrissur",
        address: "PARAVATHANI, THRISSUR",
        phone: "9895610532"
    },
    {
        id: 6,
        distributers: "Orion All Store",
        district: "Palakkad",
        address: "SANKARAMANGALAM, PALAKKAD",
        phone: "9846208719"
    },
    {
        id: 7,
        distributers: "Orion Cell Store LLP",
        district: "Ernakulam",
        address: "KALAKKASSERI, KUMBALAM, ERNAKULAM",
        phone: "7034179777"
    },
    {
        id: 8,
        distributers: "Orion Ware House",
        district: "Kannur",
        address: "BRANCH, THIRICHAMBARAM, THALIPARAMBA, KANNUR",
        phone: "7510868788"
    },
    {
        id: 9,
        distributers: "Zoom Batteries",
        district: "Kottayam",
        address: "Nagampadam, Kottayam",
        phone: "9400602128"
    }
]
