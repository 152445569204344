import React from 'react'
import Title from '../Shared/Title'

const ContactUsContent = () => {
    return (
        <div className='pb-12 lg:pb-32'>
            <div className='hidden lg:block'>
                <Title titleRed={"FIND US"}
                    titleWhite={"HERE"} />
            </div>
            <div className='flex justify-center flex-col lg:flex-row w-full mt-[10%] items-center gap-10 lg:gap-0'>
                <div className=' flex flex-col justify-center items-center gap-5 lg:w-[45%] sm:w-[70%] w-[80%]'>
                    <div className='rounded-lg w-[75%] '><iframe title='ss' className='w-full rounded-2xl  md:h-[400px] sm:h-[300px] h-[200px]' frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3912.978058183143!2d75.89264017643126!3d11.26302305002702!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba65b51eb0b8f43%3A0xb15c3055d53f643e!2sOrion%20Battery!5e0!3m2!1sen!2sin!4v1710953482377!5m2!1sen!2sin"><a href="https://www.maps.ie/population/">Calculate population in area</a></iframe></div>
                    <h5 className='text-[#E53A10] text-[18px] font-ArgentumSans500 font-normal text-center ' >Address</h5>
                    <p className='text-base font-normal text-center text-white font-ArgentumSans300  xl:w-[40%]'>Orion Battery, Poovattumparamba P.O Peruvayal, Kozhikode, Kerala, 673008</p>
                    <div className='flex items-center justify-center gap-5 '>
                        <img className='w-8 h-8' src={require('../../assets/phone.png')} alt="" />
                        <p className='text-center text-white text-[18px] font-light font-argentumSans leading-10'>0495 2492329</p>
                    </div>
                    <div className='flex items-center justify-center gap-5 '>
                        <img className='w-8 h-8' src={require('../../assets/mail.png')} alt="" />
                        <p className='text-center text-white text-[18px] font-light font-argentumSans leading-10'>info@orioncells.com</p>
                    </div>
                </div>
                <div className=' flex flex-col justify-center items-center gap-5 lg:w-[45%] sm:w-[70%] w-[80%]'>
                    <div className='rounded-lg w-[75%] '><iframe className='w-full rounded-2xl  md:h-[400px] sm:h-[300px] h-[200px] ' frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d31343.536640101964!2d77.1690316!3d10.8920065!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba8538c10b9fa4b%3A0xe5177197ec6aab18!2sOrion%20battery%20coimbatore!5e0!3m2!1sen!2sin!4v1710953157639!5m2!1sen!2sin"><a href="https://www.maps.ie/population/">Calculate population in area</a></iframe></div>
                    <h5 className='text-[#E53A10] text-[18px] font-ArgentumSans500 font-normal text-center ' >Address</h5>
                    <p className='text-base font-normal text-center text-white font-ArgentumSans300  xl:w-[40%]'>309/1A3, Puliyamarathupalayam, Varapatti, Coimbatore, Tamil Nadu, 641669</p>
                    <div className='flex items-center justify-center gap-5 '>
                        <img className='w-8 h-8' src={require('../../assets/phone.png')} alt="" />
                        <p className='text-center text-white text-[18px] font-light font-argentumSans leading-10'>0495 2492329</p>
                    </div>
                    <div className='flex items-center justify-center gap-5 '>
                        <img className='w-8 h-8' src={require('../../assets/mail.png')} alt="" />
                        <p className='text-center text-white text-[18px] font-light font-argentumSans leading-10'>info@orioncells.com</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactUsContent