import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';
import 'swiper/swiper-bundle.css';

const Testimonials = () => {
  const testimonials = [
    {
      id: 1,
      name: 'Suresh Kumar',
      text: "I had the chance to speak with Mr. MP BABU (ORION BATTERY). He was representing the Manufacturers Association, and I was the BDA representative. We had a face-to-face conversation during the joint meeting (gather & grow) between the Battery Dealers and Distributors Association and the representatives of the battery manufacturers at the Hotel Holiday Inn Kochi. His knowledge of the battery industry and his insights were truly precious and invaluable. I extend a special and heartfelt thank you to Mr. Babu M. P., Chairman of Orion Battery, who also serves as the organization's Founder Secretary, for attending the meeting.",
      img: require('../../assets/suresh.jpg')
    },
    {
      id: 2,
      name: 'Muhammed Shafi M P',
      text: "Orion Battery has been succeeding for the last 20 years, and this growth can be attributed to both the high caliber of their batteries and their after-sales support. Being the Orion distributor for Malappuram and Palakkad district, I can attest to the benefits the Orion brand has had on my professional life.",
      img: require('../../assets/shafi.jpg')

    },
    {
      id: 3,
      name: 'Praveesh',
      text:"Kerala's no. 1 brand, Orion. Fast in delivery and aftersales service. As a distributor, my customers are very satisfied.",
      img: require('../../assets/praveesh.jpg')
    }
  ]
  return (
    <div className="mt-32 w-[80%] container mx-auto">
      <div className="text-center">
        <h4 className="text-orange-600 font-koho text-[35px] my-10">What Our Customers Say ?</h4>
      </div>
      <Swiper
        spaceBetween={10}
        slidesPerView={1}
        modules={[Autoplay, Navigation]}
        navigation
        pagination={{ clickable: true }}
        autoplay={{
          delay: 2000,
          disableOnInteraction: true
        }}
        loop
        onSlideChange={() => console.log('slide change')}
      >
        {
          testimonials.map((testimonial, index) => (
            <SwiperSlide key={index} className=''>
              <div className="flex items-center justify-center rounded-xl bg-neutral-100 min-h-[500px] ">
                <div className="flex flex-col items-center justify-center py-5 lg:gap-20 gap-7 lg:flex-row lg:py-0">
                  <img src={testimonial.img} alt="" className="object-contain w-40 h-40 border-4 border-orange-600 rounded-full" />
                  <div className="flex flex-col justify-center w-1/2 gap-4 lg:py-20">
                    <p className="font-gilroy text-sm lg:text-[18px] lg:leading-[32px]">
                      {testimonial.text}
                    </p>
                    <p className="font-gilroy text-sm lg:text-[18px] lg:leading-[32px]">{testimonial.name}</p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))
        }
      </Swiper>
    </div>
  );
};

export default Testimonials;
