import React from 'react'
import '../styles/MobileArrival.scss'
import { batteries } from '../data'
import { handleEnquiry } from '../enquiry';

function MobileArrival() {

    return (
        <div className=''>
            <div className="container mx-auto">
                <div className="mobilearrival-head">
                    <h3 className='hot'>NEW</h3><h3 className='deal'>ARRIVALS</h3>
                </div>
                <div className=" mobilearrival-section">
                    <div className='grid grid-cols-2 gap-4 mobarrival-partone'>
                        {
                                batteries.filter(battery => battery.newArrival).map((battery, index) => (
                                    <div className={`mobilearrival-first${batteries.length === 3 && index === 2 ? 'col-span-2 justify-self-center ' : ''}`} key={index}>
                                        <div className=' mobilerounded-part'>
                                            <h3>{battery.category}</h3>
                                            <h4>{battery.series}</h4>
                                            <p>{battery.name}</p>
                                            <img className='mobilebattery' src={battery.image} alt="" />
                                            <h4>Capacity {battery.capacity}Ah {battery.time ? `@${battery.time}Hr` : ''}</h4>
                                            <h4>Warranty {battery.warranty} {battery.extendedWarranty ? `+ ${battery.extendedWarranty}` : ''} months</h4>
                                            <button className='px-1 py-1 mt-2 text-xs text-white rounded-md ' style={{ backgroundColor: "#E53A10" }} onClick={() => handleEnquiry(battery)} >Enquire Now</button>
                                        </div>
                                    </div>
                                )) 

                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MobileArrival