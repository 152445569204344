import React from 'react'
import energy from "../../assets/Energy 1.svg";
import qualityControl from '../../assets/QC 1.png';
import support from '../../assets/support-technology-svgrepo-com 1.svg';

const MantraList = () => {


  return (
    <div className='grid grid-cols-1 gap-20 px-3 mt-24 lg:grid-cols-3'>
      <div className='flex flex-col items-center justify-between gap-10 border border-orange-600 rounded-2xl'>
        <div className='w-[220px] h-[220px] mt-10'>
          <img src={energy} alt="" className='w-full h-full' />
        </div>
        <h4 className='font-koho text-[25px] text-center max-w-[254px] h-20'>
          <span className='text-red-600'>Protecting</span> {" "}
          Environment The Orion Way
        </h4>
        <p className='p-4 text-center text-[15px] leading-relaxed font-ArgentumSans300 font-normal mb-10'>
          Development without hurting the environment. This moto is reflected in all aspects of the Orion enterprise. Our manufacturing unit is equipped with the best possible pollution control solutions so that zero air, water and sound pollution is ensured at all levels of production.
        </p>
      </div>
      <div className='flex flex-col items-center justify-between gap-10 border border-orange-600 rounded-2xl'>
        <div className='max-w-[350px] max-h-[350px] mt-10 flex justify-center'>
          <img src={qualityControl} alt="" className='w-full h-full' />
        </div>
        <h4 className='font-koho text-[25px] text-center max-w-[254px] h-20'>
          <span className='text-red-600'>Quality</span> {" "}
          Control
        </h4>
        <p className='p-4 text-center text-[15px] leading-relaxed font-ArgentumSans300 font-normal mb-10'>
          Development without hurting the environment. This moto is reflected in all aspects of the Orion enterprise. Our manufacturing unit is equipped with the best possible pollution control solutions so that zero air, water and sound pollution is ensured at all levels of production.
        </p>
      </div>
      <div className='flex flex-col items-center justify-between gap-10 border border-orange-600 rounded-2xl'>
        <div className='w-[220px] h-[220px] mt-10 flex justify-center'>
          <img src={support} alt="" className='w-[200px] h-[200px]' />
        </div>
        <h4 className='font-koho text-[25px] text-center max-w-[254px] h-20'>
          We Are Listening <span className='text-red-600'>24/7</span>  
        </h4>
        <p className='p-4 text-center text-[15px] leading-relaxed font-ArgentumSans300 font-normal mb-10'>
          Development without hurting the environment. This moto is reflected in all aspects of the Orion enterprise. Our manufacturing unit is equipped with the best possible pollution control solutions so that zero air, water and sound pollution is ensured at all levels of production.
        </p>
      </div>
    </div>
  )
}

export default MantraList