import React from 'react'
import '../../styles/homeHero.scss'
import { useNavigate } from 'react-router-dom'

const HomeHero = () => {
    const navigate = useNavigate()
    return (
        <div className='grid grid-cols-6'>
            <div className='col-span-3'>
                <div className='w-full h-full '>
                    <div className='flex items-end w-full h-full '>
                        <img className='object-contain w-full h-full' src={require('../../assets/battery.gif')} alt="" />
                    </div>
                </div>
            </div>
            <div className='col-span-3'>
                <div className='container flex flex-col items-center mx-auto'>
                    <div className='flex flex-col items-end h-full '>
                        <div className='relative flex flex-col items-end h-full'>
                            <div className='flex flex-col justify-end h-full ml-20 text-right head'>
                                <h1 className="text-4xl font-semibold text-[#E0390F] xl:text-5xl 2xl:text-5xl font-argentumSans">Unleash</h1>
                                <h3 className="text-4xl font-semibold text-white xl:text-5xl 2xl:text-5xl font-argentumSans">Reliable Power</h3>
                            </div>
                        </div>
                        <div className='flex flex-col items-end mt-6 ml-20 xl:mt-12 '>
                            <h4 className='text-white  text-2xl  font-normal font-argentumSans  leading-[46.47px]'>Elevate Your Energy</h4>
                            <h4 className='text-white  text-2xl  font-normal font-argentumSans  leading-[46.47px]'>Needs to the Next Level</h4>
                            <div className='mt-16'>
                                <button className='text-white 2xl:text-2xl text-xl font-normal font-argentumSans500  leading-[38.72px] p-3 2xl:p-5  rounded-xl justify-center items-center gap-2.5 inline-flex' style={{ backgroundColor: "#E53A10" }} onClick={() =>  navigate('/products')} >Explore Products</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default HomeHero