import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import '../styles/hero.scss';
import { useMediaQuery } from 'react-responsive';
import MobileHero from './MobileHero';

function Hero() {
  const isMobile = useMediaQuery({ query: "(max-width: 1024px)" });

  return (
    <>
      {
        isMobile ?
          <MobileHero />
          :
          <div className="relative container-fluid">
            <div className='absolute round_one'>
              <img className='w-full h-full' src={require('../assets/round.png')} alt="" />
            </div>
            <Swiper
              spaceBetween={50}
              slidesPerView={1}
              modules={[Autoplay]}
              pagination={{ clickable: true }}
              autoplay={{ delay: 2500 }}
              speed={2000}
            
              loop
            >
              <SwiperSlide >
                <img className="w-full" src={require('../assets/slider/bg-bike.webp')} alt="Powering Two-Wheelers" />
                <div className="carousel-caption">
                  <div className="container hero-content ">
                    <h5 >Powering Two - Wheelers</h5>
                    <p>Powering Adventures</p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <img className="w-full" src={require('../assets/slider/bg-home.webp')} alt="Uninterrupted Power" />
                <div className="carousel-caption">
                  <div className="container hero-content">
                    <h5>Uninterrupted Power</h5>
                    <p>For your Home</p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <img className="w-full" src={require('../assets/slider/bg-car.webp')} alt="Power Your Drive" />
                <div className="carousel-caption">
                  <div className="container hero-content">
                    <h5>Power Your Drive</h5>
                    <p>Reliable, Powerful</p>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
      }

    </>
  );
}

export default Hero;
