export const Allstore=[
    {
    id:1,
    distributers:"Orion All Store",
    district:"Kozhikode",
    address:"Golf Link Road, Chevarambalam, Kozhikode ",
    phone:"9961918666"
}, {
    id:2,
    distributers:"Orion All Store",
    district:"Malappuram",
    address:"Pallipadi, Pandallur, Malappuram",
    phone:"9846208719"
}, {
    id:3,
    distributers:"Orion All Store",
    district:"Malappuram",
    address:"Musliyarangadi, Edakkara",
    phone:"9447430785"
}, {
    id:4,
    distributers:"Orion All Store",
    district:"Malappuram",
    address:"Pathapriyam, Edavanna",
    phone:"9447430785"
}, {
    id:5,
    distributers:"Orion All Store",
    district:"Thrissur",
    address:"Koonamochi, THRISSUR",
    phone:"9447309011"
}, {
    id:6,
    distributers:"Orion All Store",
    district:"Thrissur",
    address:"Mannuthy, THRISSUR",
    phone:"8089621128"
}, {
    id:7,
    distributers:"Orion All Store",
    district:"Palakkad",
    address:"SANKARAMANGALAM, PALAKKAD",
    phone:"9846208719"
}, {
    id:8,
    distributers:"Orion All Store LLP",
    district:"Ernakulam",
    address:"KALAKKASSERI, KUMBALAM, ERNAKULAM",
    phone:"7034179777"
},



]