import React from 'react'
import { cateories, series } from '../../data';

const ProductFilter = ({ handleCategory, handleSeries, setSearch , handleSearch }) => {

    return (
        <div className=''>
            <div className='container relative grid grid-cols-10 gap-6 mx-auto mt-[8%] 2xl:mt-[12%]'>
                <div className='col-span-3 m'>
                    <div className=' relative'>
                        <select
                            id="yourSelect"
                            className="w-full h-16 px-[45px] pr-9 py-[18px] bg-neutral-900 rounded-[56px] justify-center items-center gap-2.5 inline-flex text-white xl:text-xl text-sm font-light font-['Argentum Sans'] leading-7 flex gap-5 items-center appearance-none"
                            onChange={(e) => handleCategory(e.target.value)}
                        >
                            <option className='' value={"All"}>Select Category</option>
                            {
                                cateories.map((category, index) => (
                                    <option
                                        value={category.slug}
                                        key={index}
                                    >
                                        {category.name}
                                    </option>
                                ))
                            }
                        </select>
                        <img className='w-7 absolute top-[45%] right-[10%]' src={require('../../assets/dropdown.png')} alt="" />
                    </div>
                </div>
                <div className='col-span-3 m'>
                    <div className=' relative'>
                        <select
                            id="yourSelect"
                            className="w-full   h-16 px-[45px] pr-9 py-[18px] bg-neutral-900 rounded-[56px] justify-center items-center gap-2.5 inline-flex text-white xl:text-xl text-sm font-light font-['Argentum Sans'] leading-7 flex gap-5 items-center appearance-none "
                            onChange={(e) => handleSeries(e.target.value)}
                        >
                            <option value="All">Select Series</option>
                            {
                                series.map((serie, index) => (
                                    <option
                                        value={serie.name}
                                        key={index}
                                    >
                                        {serie.name}
                                    </option>
                                ))
                            }
                        </select>
                         <img className='w-7 absolute top-[45%] right-[10%]' src={require('../../assets/dropdown.png')} alt="" />
                    </div>
                </div>
                <div className='col-span-4 m'>
                    <div className="relative ">

                        <input
                            type="search"
                            id="default-search"
                            className="block w-full p-4 h-16 ps-10 text-base text-white  rounded-[56px] bg-neutral-900  dark:bg-gray-700 border border-none  dark:placeholder-gray-400 dark:text-white "
                            placeholder="Search Products"
                            required
                            onChange={(e) => setSearch(e.target.value)  }
                        />
                        <button type="submit" onClick={handleSearch} className=" text-neutral-900 absolute end-2.5 bottom-4 flex items-center gap-3 bg-white  focus:ring-4 focus:outline-none  font-medium rounded-[56px] text-sm px-4 py-2">Search <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                        </svg> </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductFilter