import React, { useEffect } from 'react'
import Footer from '../components/Footer'
import Hero from '../components/Shared/Hero'
import HeroImage from '../assets/products_hero.png'

import MobileFooter from '../components/MobileFooter'
import { useMediaQuery } from 'react-responsive'
import InfoCard from '../components/Shared/InfoCard'
const KnowYourBattery = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 1024px)" });
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className='flex flex-col gap-20 2xl:gap-8'>
      <Hero image={HeroImage} text={"Premium Automotive,<br>Tubular,Stationary & MC Batteries"} textWidth={657}/>
       <InfoCard heading={'Know Your Battery'}text={'The battery is the lifeline of any vehicle. So if you own a vehicle, get to know all about vehicle batteries. This section guides you through the basics of vehicle battery - how a battery works, relevant battery definitions, tips on handling and maintaining a battery, and how to deal with battery problems. The section also answers all possible questions that you may have in mind about batteries.'}/>
       {isMobile?<MobileFooter/>:<Footer/>}

    </div>
  )
}

export default KnowYourBattery