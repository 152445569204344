import React from 'react'
import '../styles/aboutUs.scss'
import Title from './Shared/Title'

function AboutUs() {
  return (
    <div className="mt-40">
      <div className='grid grid-cols-2'>
        <Title titleRed={"ABOUT"} titleWhite={"US"} />
        <Title titleRed={"QUICK"} titleWhite={"CONTACT"} />
      </div>
      <div className="container mx-auto">
        <div className="grid grid-cols-2 about-main">
          <div className="about-section col-sm-6">
            {/* <Title titleRed={"ABOUT"} titleWhite={"US"} /> */}
            <div className='about-para'>
              <p className='first-para'>Orion is a company manufacturing and marketing reliable and cost-effective Batteries ranging from 2.5 Ah to 300 Ah, Automotive, Tubular stationary and MC Battaries,under the Orion brand name</p>
              <p className='second-para'>The Orion brand has a unique position in the market with competitive pricing, product quality and a round the clock after sales service</p>
            </div>
            <div className="about-account">
              <div className="follow-us">
                <p>Follow Us On</p>
              </div>
              <div className="social-accounts">
                <div>
                  <img src="/images/insta.png" alt="" />
                </div>
                <div>
                  <img src="/images/facebook-icon.png" alt="" />
                </div>
                <div>
                  <img src="/images/youtube.png" alt="" />
                </div>
                <div>
                  <img src="/images/twitter-icon.png" alt="" />
                </div>
              </div>

            </div>
            <div className="customer-service">
              <img src="/images/customer.png" alt="" />
              <h3>Customer Care :</h3>
              <p>+91586232151</p>
            </div>
          </div>
          <div className="form">

            <div className="form-content">
              <form >
                <input type="text" placeholder='Name' className='text-white' />
                <input type="text" placeholder='E-Mail' className='text-white' />
                <input type="text" placeholder='Mobile Number' className='text-white' />
                <input className='text-white message' type="text" placeholder='Message'  />
                <button>SEND</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutUs