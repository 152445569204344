import React from "react";

const Table = ({ data, headline }) => {
  const heading = ["Distributers", "District", "Address"];

  return (
    <div className="px-1 sm:px-4 basis-3/4">
      <div className="container mx-auto text-white flex flex-col sm:gap-[109px] gap-2 sm:p-0 px-[36px]">
        <h4 className="text-3xl text-center text-white">{headline}</h4>
        <div className="table-fixed border-[1px]  border-solid rounded-2xl">
          <table className="w-full">
            <thead className="">
              <tr>
                {heading.map((value, index) => (
                  <th
                  className={`border-b-[1px] border-x-[1px] border-solid sm:pt-10 sm:pb-6 lg:text-base md:text-xs sm:text-[10px] text-[7px] ${
                    index === 0 ? 'border-l-0' : ''} ${index === heading.length - 1 ? 'border-r-0' : ''}`}
                  style={{ color: "#E53A10" }}
                  key={index}
                >
                    {value}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((value, index) => (
                <tr className="pt-10 font-light" key={index}>
                  <td className="   border-solid md:px-[40px] px-1 sm:px-4 pt-2 pb-1 sm:pb-4 sm:pt-4  lg:pt-6 lg:pb-6 lg:text-base md:text-xs sm:text-[10px] text-[7px]">
                    {value.distributers}
                  </td>
                  <td className=" border-x-[1px] border-solid pt-2 pb-1 md:px-[20px] px-1 sm:px-4 sm:pb-4 sm:pt-4  lg:pt-6 lg:pb-6 lg:text-base md:text-xs sm:text-[10px] text-[7px]">
                    {value.district}
                  </td>
                  <td className=" border-x-[1px] border-solid pt-2 pb-1 md:px-[40px] px-1 sm:px-4 sm:pb-4 sm:pt-4  lg:pt-6 lg:pb-6 lg:text-base md:text-xs sm:text-[10px] text-[7px]">
                    {value.address}
                  </td>
                  {/* <td className="md:px-[40px] px-1 sm:px-4 sm:pb-4 sm:pt-4 pt-2 pb-1 lg:pt-6 lg:pb-6 lg:text-base md:text-xs sm:text-[10px] text-[7px]">{value.phone}</td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Table;
