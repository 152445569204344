import React, { useEffect } from 'react'
import { batteries } from '../../data'
import { useMediaQuery } from 'react-responsive'
import ProductFilter from './ProductFilter'
import { handleEnquiry } from '../../enquiry';
import '../../styles/products.scss'

const Product = () => {
    const [search, setSearch] = React.useState('')
    const [filteredBatteries, setFilteredBatteries] = React.useState(batteries)
    const isMobile = useMediaQuery({ query: "(max-width: 1024px)" });

    useEffect(() => {
        if (search === '') return setFilteredBatteries(batteries)
    }, [search])
    const handleCategory = (category) => {
        if (category === 'All') return setFilteredBatteries(batteries)
        setFilteredBatteries(batteries.filter(battery => battery.category === category))
    }
    const handleSeries = (series) => {
        if (series === 'All') return setFilteredBatteries(batteries)
        setFilteredBatteries(batteries.filter(battery => battery.series === series))
    }
    const handleSearch = () => {
        if (search === '') return setFilteredBatteries(batteries)
        setFilteredBatteries(batteries.filter(battery => battery.name.toLowerCase().includes(search.toLowerCase())))
    }
    return (
        <div className=''>
            {
                isMobile ?
                    null :
                    <ProductFilter
                        handleCategory={(data) => handleCategory(data)}
                        handleSeries={(data) => handleSeries(data)}
                        setSearch={setSearch}
                        handleSearch={handleSearch}


                    />
            }
            <div className="container w-full pt-10 pb-32 mx-auto">
                <div className="grid grid-cols-2 gap-10 mt-8 md:grid-cols-3 ">
                    {
                        filteredBatteries.map((battery, index) => (
                            <div className="product-first" key={index}>
                                <div className='rounded-part'>
                                    <h3 className='text-white capitalize'>{battery.category}</h3>
                                    <h4>{battery.series}</h4>
                                    <p>{battery.name}</p>
                                    <img className='battery' src={battery.image} alt="" />

                                </div>
                                <div className='button_enquiry'>'
                                    <h4 className=' text-white font-argentumSans font-normal text-[15px]'>Capacity {battery.capacity}Ah {battery.time ? `@${battery.time}Hr` : ''}</h4>
                                    <h4 className=' text-white font-argentumSans font-normal text-[15px]'>Warranty {battery.warranty} {battery.extendedWarranty ? `+ ${battery.extendedWarranty}` : ''} months</h4>
                                    <button className='px-4 py-3 text-white transition-transform duration-300 mt-7 hover:scale-125' style={{ backgroundColor: "#E53A10" }} onClick={() => handleEnquiry(battery)} >Enquire Now</button>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default Product