import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import { useMediaQuery } from 'react-responsive';
import image1 from '../../assets/About-us/image1.webp';
import image2 from '../../assets/About-us/image2.webp';
import image3 from '../../assets/About-us/image3.webp';
import image4 from '../../assets/About-us/image4.webp';
import image5 from '../../assets/About-us/image5.webp';
import image6 from '../../assets/About-us/image6.webp';


const OurMantra = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const isMobile = useMediaQuery({ query: "(max-width: 1024px)" });
  const images = [image1, image2, image3, image4, image5, image6]
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex(prevIndex => (prevIndex + 1) % 6);
    }, 2500);

    return () => clearInterval(interval);
  }, []);
  return (
    <>
      {
        isMobile ?
          <>

            <Swiper
              modules={[Autoplay]}
              spaceBetween={50}
              slidesPerView={1}
              autoplay={{ delay: 2000 }}
              speed={2000}
              onSlideChange={(swiper) => setCurrentIndex(swiper.activeIndex)}
              className="w-full"
            >
              {
                images.map((data, index) => (
                  <SwiperSlide key={index}>
                    <img
                      key={index}
                      src={data}
                      alt={"s"}
                      className='rounded-lg'
                    />
                  </SwiperSlide>
                ))}
            </Swiper>
            <div className='flex flex-col justify-center gap-10'>
              <div className="hidden text-3xl lg:block">
                <span className="font-normal text-red-600 font-koho">OUR</span>
                <span className="font-normal text-black font-koho"> MANTRA</span>
              </div>
              <div className="text-lg leading-10 sm:text-2xl font-ArgentumSans">"Orion believes that our most valuable quality is our inner power- the power to go beyond conventional wisdom and achieve the seemingly unachievable, to imagine and create things afresh and anew, and most importantly, to explore camaraderie among people and create happy and successful teams."</div>
            </div>
          </>
          :
          <div className='px-4 pt-4'>
            <div className="flex items-center justify-center w-full mb-10 text-3xl text-center lg:hidden">
              <span className="font-normal text-center text-red-600 font-koho">OUR</span>
              <span className="font-normal text-black font-koho"> MANTRA</span>
            </div>
            <div className='grid grid-cols-1 gap-10 lg:grid-cols-1'>
              <div className='flex justify-between w-full overflow-hidden xl:pb-20 md:pb-10 md:gap-4 sm:pb-5 sm:gap-2 sm:overflow-visible '>
                {images.map((data, index) => (
                  <img
                    key={index}
                    src={data}

                    className={`transition-ease duration-[1000ms] ${index === currentIndex ? 'flex-1 xl:w-[700px] xl:rounded-[24px] xl:h-[800px] lg:w-[500px] lg:h-[400px] md:w-[450px] md:h-[300px] sm:w-[400px] sm:h-[290px] h-[800px]  sm:rounded-[16px]  rounded-[12px] w-full h-[290px]  object-cover' :
                      ' xl:w-[70px] xl:h-[800px]  xl:rounded-[24px] lg:w-[70px] lg:h-[400px] md:w-[40px] md:h-[300px] sm:w-[30px] sm:h-[290px] md:rounded-[16px] sm:rounded-[16px] w-full  h-[290px] w-0  sm:flex rounded-[12px] object-cover  '}`}
                    alt={"s"}
                  />))}

              </div>
              <div className='flex flex-col justify-center gap-10'>
                <div className="hidden text-3xl lg:block">
                  <span className="font-normal text-red-600 font-koho">OUR</span>
                  <span className="font-normal text-black font-koho"> MANTRA</span>
                </div>
                <div className="text-lg leading-10 sm:text-2xl font-ArgentumSans">"Orion believes that our most valuable quality is our inner power- the power to go beyond conventional wisdom and achieve the seemingly unachievable, to imagine and create things afresh and anew, and most importantly, to explore camaraderie among people and create happy and successful teams."</div>
              </div>
            </div>
          </div>
      }
    </>
  )
}

export default OurMantra