import React, { useEffect, useRef, useState } from "react";
import "../styles/header.scss";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

function Header() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [activetab, setActiveTab] = useState("home")
  const myDivRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    const pathToTabMap = {
      "/": "home",
      "/about-us": "about-us",
      "/blog": "blog",
      "/contact-us": "contact-us",
      "/warranty": "warranty",
      "/projects": "projects",
      "/meet-the-captain": "more",
      "/certifications": "more",
      "/distributors": "more",
      "/know-your-battery": "more",
      "/products": "products"
    };

    const currentTab = pathToTabMap[location.pathname];
    setActiveTab(currentTab || "home"); // Default to "home" if no match found
  }, [location]);


  const handleClick = (event) => {
    if (myDivRef.current && myDivRef.current.contains(event.target)) {
      // Clicked inside the dropdown, do nothing
      return;
    }

    // Clicked outside the dropdown, close it
    setOpen(false);
  };

  const handleSubMenuClick = () => {
    // Clicked on the parent div, toggle the dropdown
    setOpen((prevOpen) => !prevOpen);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    document.addEventListener("touchstart", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
      document.removeEventListener("touchstart", handleClick);
    };
  }, []);
  const renderTabDivider = (tabName) => {
    return activetab === tabName && (
      <hr
        className="w-2/3 border-[1.5px]"
        style={{ borderColor: "#E53A10" }}
      />
    );
  };
  return (
    <div className="relative flex flex-col justify-center h-28 z-4">
      <div className="container flex h-full mx-auto">
        <div className="grid items-center justify-between w-full grid-cols-10 p-3">
          <div className="flex items-start col-span-2 ">
            <div className="cursor-pointer lg:w-48 xl:w-64">
              <a href="/">
                <img src={require('../assets/orion.png')} alt="" className="w-full " onClick={() => navigate('/')} />
              </a>
            </div>
          </div>
          <div
            className="flex items-center justify-end w-full col-span-8 gap-10 cursor-pointer "
            style={{ fontFamily: "ArgentumSans-300" }}
          >
            <a href="/">
              <div className="flex items-center gap-1 ">
                <span className="flex flex-col justify-between ">
                  <h3
                    className="text-base font-light text-white ArgentumSans300"
                    style={{ color: activetab === "home" && "#E53A10" }}
                  >
                    HOME
                  </h3>
                  {renderTabDivider("home")}
                </span>
              </div>
            </a>
            <a href="/products">
              <div className="flex items-center gap-1 ">
                <span className="flex flex-col justify-between ">
                  <h3
                    className="text-base font-light text-white ArgentumSans300"
                    style={{ color: activetab === "products" && "#E53A10" }}
                  >
                    PRODUCTS
                  </h3>
                  {renderTabDivider('products')}
                </span>
              </div>
            </a>
            <a href="/about-us">
              <div className="flex items-center gap-1 ">
                <span className="flex flex-col justify-between ">
                  <h3
                    className="text-base font-light text-white ArgentumSans300"
                    style={{ color: activetab === "about-us" && "#E53A10" }}
                  >
                    ABOUT US
                  </h3>
                  {renderTabDivider('about-us')}
                </span>
              </div>
            </a>
            <a href="/contact-us">
              {" "}
              <div className="flex items-center gap-1 ">
                <span className="flex flex-col justify-between ">
                  <h3
                    className="text-base font-light text-white ArgentumSans300"
                    style={{ color: activetab === "contact-us" && "#E53A10" }}
                  >
                    CONTACT US
                  </h3>
                  {renderTabDivider('contact-us')}
                </span>
              </div>
            </a>
            <a href="/blog">
              {" "}
              <div className="flex items-center gap-1 ">
                <span className="flex flex-col justify-between ">
                  <h3
                    className="text-base font-light text-white ArgentumSans300"
                    style={{ color: activetab === "blog" && "#E53A10" }}
                  >
                    BLOGS
                  </h3>
                  {renderTabDivider('blog')}
                </span>
              </div>
            </a>
            <div
              className="relative"
              ref={myDivRef}
              onClick={handleSubMenuClick}
            >
              <div className="flex items-center gap-1 ">
                <span className="flex flex-col justify-between ">
                  <h3
                    className="text-base font-light text-white ArgentumSans300"
                    style={{ color: activetab === "more" && "#E53A10" }}
                  >
                    MORE
                  </h3>
                  {renderTabDivider('more')}
                </span>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="10"
                    viewBox="0 0 16 10"
                    fill="none"
                  >
                    <path
                      d="M0 0L8.47059 3.5L16 0L8.47059 10L0 0Z"
                      fill={`${activetab === "more" ? "#E53A10" : "white"}`}
                    />
                  </svg>
                </span>
              </div>
              {open && (
                <div className="absolute top-[50px] w-[265px] z-40 bg-white right-0">
                  <ul className="flex flex-col justify-center pl-4 py-7">
                    <a href="/meet-the-captain">
                      <li
                        className="pb-2 border-b "
                        style={{ borderColor: "#C6C6C6" }}
                      >
                        Meet the Captain
                      </li>
                    </a>
                    <a href="/projects">
                      <li
                        className="py-2 border-b "
                        style={{ borderColor: "#C6C6C6" }}
                      >
                        Our Projects
                      </li>
                    </a>
                    <a href="/certifications">
                      <li
                        className="py-2 border-b "
                        style={{ borderColor: "#C6C6C6" }}
                      >
                        Awards and Certifications
                      </li>
                    </a>
                    <a href="/distributors">
                      <li
                        className="pb-2 border-b pt-7 "
                        style={{ borderColor: "#C6C6C6" }}
                      >
                        Distributors
                      </li>
                    </a>
                    <a href="/warranty">
                      <li
                        className="py-2 border-b "
                        style={{ borderColor: "#C6C6C6" }}
                      >
                        Online Warranty
                      </li>
                    </a>
                    <a href="know-your-battery">
                      <li
                        className="py-2 border-b "
                        style={{ borderColor: "#C6C6C6" }}
                      >
                        Know Your Battery
                      </li>
                    </a>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
