import React from 'react'

const Preloader = () => {
  return (
    <div className='flex items-center justify-center h-screen overflow-hidden'>
      <img src={require('../../assets/orion.png')} alt="Orion" className="animate-pulse" />
    </div>
  )
}

export default Preloader