import React, { useEffect } from 'react'

const HeroV2 = ({ title, background }) => {
  useEffect(() => {
    document.getElementById('hero').style.backgroundImage = `url(${background})`
    document.getElementById('hero').style.backgroundSize = 'cover'
    document.getElementById('hero').style.backgroundRepeat = 'no-repeat'
    document.getElementById('hero').style.backgroundColor = 'rgba(0, 0, 0, 0.7)'; 
  }, [ background])
  return (
    <div className={`h-[60vh] bg-center flex justify-center items-center  bg-blend-multiply	 `} id="hero">
      <h4 className='text-white text-center font-ArgentumSans600 text-[40px]'>{title}</h4>
    </div>
  )
}

export default HeroV2