import React from 'react'

const Title = ({titleRed, titleWhite}) => {
  return (
    <div className='flex items-center justify-between gap-2'>
      <div class="border-b border border-gap border-[#E53A10]  2xl:w-[38%] xl:w-[35%] md:w-[32%] sm:w-[29%] xs:w-[26%] w-[15%]">
      </div>
      <h4 className='text-center text-white text-[20px] font-ArgentumSans300'>
        <span className='text-red-600 text-[20px] font-ArgentumSans300'>{titleRed}</span> 
         {" "}{titleWhite}
      </h4>
      <div class="border-b border border-gap border-[#E53A10]  2xl:w-[38%] xl:w-[35%] md:w-[32%] sm:w-[29%] xs:w-[26%] w-[15%]">
      </div>
    </div>
  )
}

export default Title