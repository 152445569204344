import React, { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import Footer from "../components/Footer";
import MobileFooter from "../components/MobileFooter";
import InfoCard from "../components/Shared/InfoCard";
import HeroTwo from "../components/MeetTheCap/HeroTwo";
import { data } from "../components/MeetTheCap/Data";

const MeetTheCap = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 1024px)" });
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="flex flex-col gap-20 lg:gap-0">
      <HeroTwo />
      <div className="sm:mt-80 lg:px-20 md:px-10 sm:px-10 xl:mt-96 lg:mt-40 md:mt-40">
        {data.map((value, key) => (
          <InfoCard text={value.text} subHeading={value.subHeading} />
        ))}
      </div>

      {isMobile ? <MobileFooter /> : <Footer />}
    </div>
  );
};

export default MeetTheCap;
