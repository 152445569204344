import React from 'react'
import Footer from '../components/Footer'
import Hero from '../components/Shared/Hero'
import HeroImage from '../assets/batterytwo.png'
import { useMediaQuery } from 'react-responsive'
import MobileFooter from '../components/MobileFooter'
import InfoCard from '../components/Shared/InfoCard'

const Waranty = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 1024px)" });
  return (
    <div className='flex flex-col gap-20 2xl:gap-8 sm:gap-28'>
      <div className='h-[60vh] bg-[#0D0D0D] flex justify-center items-center '>
        <div className='flex flex-wrap items-center justify-center gap-5'>
          <p className='text-white font-ArgentumSans500 text-base lg:text-[25px]'>Are you a </p>
          <a href='https://crm.orioncells.com/customer/form/reg_form' target='_blank' rel="noreferrer">
            <button className='bg-[#E53A10] text-white font-ArgentumSans500 text-base lg:text-[25px] p-3' >Customer</button>
          </a>
          <p className='text-white font-ArgentumSans500 text-base lg:text-[25px]'>or</p>
          <a href='https://crm.orioncells.com/customer/form' target='_blank' rel="noreferrer" >
            <button className='border border-[#E53A10] text-white font-ArgentumSans500 text-base lg:text-[25px] p-3'>Dealer</button>
          </a>
        </div>
      </div>
      {/* <Hero image={HeroImage} text={"Premium Automotive,<br>Tubular,Stationary & MC Batteries"} textWidth={657}/> */}
      <InfoCard heading={'Online Warranty'} text={'The battery is warranted against all manufacturing defects and faulty workmanship. However consequential liabilities shall not be entertained.All warranty claims must be accompanied by the original Dealer Sale Bill in untampered condition / UIC. In the event of any complaint, Customer is required to register the same by calling at the Toll Free No.18002700550 with the UIC/Mobile Number/Barcode NumberIn case a replacement battery is provided free of cost as settlement of the claim, the warranty period on the replacement battery would commence from the date of sale of the original battery and not from date of replacement. For batteries purchased on pro – rata warranty settlement discount, a fresh warranty is applicable from the date of purchase.The defective battery arising out of the free replacement or pro - rata settlement under this warranty will become the property of the company and no scrap rebate will be given for it.<br /><br /><br /><br />Re-charging is not covered under the purview of this warranty and shall be charged extra.Warranty will be void if:<br /><br />a.{")"} The battery is transferred to any other vehicle or application.<br />b.)Damage to the battery is caused by accident, fire and faulty electrical system.<br />c.)Breakage of battery due to mechanical shock/contamination of electrolyte.d.)Bulging of battery due to overcharging.<br />e.) Battery is fitted in application not recommended by the company.<br />f.)In the event of any particular battery model being phased out, the company reserves the right to provide another model of the same capacity,         suited to the application/ vehicle as settlement of warranty.<br /><br /><br /><br />The above terms and conditions are indicative and not exhaustive.Claims/dispute if any, to this warranty shall only be made before the courts having jurisdiction in Kozhikode'} />
      {isMobile ? <MobileFooter /> : <Footer />}
    </div>
  )
}

export default Waranty
