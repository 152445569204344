import React from 'react'
import { useNavigate} from 'react-router-dom'

const MobileHeroHome = () => {
    const navigate = useNavigate()
    return (
        <div className=''>
            <div className='absolute w-full'>
                <img className='w-full h-full' src={require('../../assets/mobileEclipse.png')} alt="" />
            </div>
            <div className='container relative mx-auto '>
                <div className='flex flex-col items-center justify-center w-full gap-10 text-center '>
                    <div className='flex flex-col gap-5 mt-12 sm:mt-32'>
                        <h1 className='text-white text-xl sm:text-[30px] md:text-[35px] font-medium md:font-bold leading-[0px]  sm:leading-7 m-0'>Unleash</h1>
                        <h1 className='text-white text-xl sm:text-[30px] md:text-[35px] font-medium md:font-bold leading-[0px] sm:leading-7 m-0'>Reliable Power</h1>
                    </div>
                    <div>
                        <p className='w-[218px] text-center text-white text-[13px] md:text-lg font-light  leading-tight'>
                            Elevate Your Energy Needs to the Next Level
                        </p>
                    </div>
                    <div className="mt-12 sm:mt-48 md:mt-64">
                        <div>
                            <button className='text-white md:text-[25px] sm:text-2xl text-base font-normal  leading-[38.72px] p-2 sm:p-4 bg-orange-600 rounded-xl justify-center items-center gap-2.5 inline-flex' onClick={() =>  navigate('/products')} >Explore Products</button>
                        </div>
                    </div>
                    <div className='h-full col-span-5 '>
                        <div className='flex items-end w-full h-full '>
                            <img className='object-contain w-full' src={require('../../assets/battery.gif')} alt="" />
                        </div>
                    </div>
                </div>


            </div>

        </div>
    )
}

export default MobileHeroHome