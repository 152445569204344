import React from 'react'
import '../styles/categories.scss'
import Title from './Shared/Title'

function Categories() {
    return (
        <div className="mt-20">
            <Title titleRed={"SHOP BY"} titleWhite={"CATEGORIES"} />
            <div className="container mx-auto">
                <div className='flex items-center justify-center w-full'>
                    <div className="flex items-center justify-center mt-20 w-full md:w-[80%]">
                        <div className='flex items-center justify-center w-full'>
                            <div className='relative bg-stone-900 w-[75%] pb-3 sm:pb-6 pr-3 sm:pr-6'>
                                <div className='eclipse_category'>
                                    <img className='w-full h-full' src={require('../assets/heroEclipse.png')} alt="" />
                                </div>
                                <div className='absolute top-8'>
                                    <img className='w-[80%]' src={require('../assets/category_1.png')} alt="" />
                                </div>
                                <div className='flex items-center justify-end w-full pt-10 sm:pt-20 lg:pt-28'>
                                    <h4 className='text-white text-[10px] sm:text-base lg:text-lg xl:text-[25px]  font-argentumSans'>TUBULAR</h4>
                                </div>
                            </div>
                        </div>
                        <div className='flex items-center justify-center w-full'>
                            <div className='relative bg-stone-900 w-[75%] pb-3 sm:pb-6 pr-3 sm:pr-6'>
                                <div className='eclipse_category'>
                                    <img className='w-full h-full' src={require('../assets/heroEclipse.png')} alt="" />
                                </div>
                                <div className='absolute top-8'>
                                    <img className='w-[80%]' src={require('../assets/category_2.png')} alt="" />
                                </div>
                                <div className='flex items-center justify-end w-full pt-10 sm:pt-20 lg:pt-28'>
                                    <h4 className='text-white text-[10px] sm:text-base lg:text-lg xl:text-[25px] font-argentumSans'>AUTOMOTIVE</h4>
                                </div>
                            </div>
                        </div>
                        <div className='flex items-center justify-center w-full'>
                            <div className='relative bg-stone-900 w-[75%] pb-3 sm:pb-6 pr-3 sm:pr-6'>
                                <div className='eclipse_category'>
                                    <img className='w-full h-full' src={require('../assets/heroEclipse.png')} alt="" />
                                </div>
                                <div className='absolute top-8'>
                                    <img className='w-[80%]' src={require('../assets/category_3.png')} alt="" />
                                </div>
                                <div className='flex items-center justify-end w-full pt-10 sm:pt-20 lg:pt-28'>
                                    <h4 className='text-white text-[10px] sm:text-base lg:text-lg xl:text-[25px] font-argentumSans'>MOTORCYCLE</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Categories