export const data = [
  {
    id: 2,
    subheading: "Research and Development",
    text: "R&D activities mainly focus on improving the quality of product by addressing the various issues in the regular process and production times. The objective of the R&D Division is to enhance the life span of the products and ensuring better performance.<br/><br/><br/>The division has a special focus on developing special batteries for renewable power production project with the technical support of AVIT College of Engineering & Technology, Chennai. Memorandum of Understanding exist with AarupadaiVeedu Institute of Technology (AVIT Chennai) and Govt. ITI Areacode with Orion Battery to train and make Electrical and Electronics Engineering Students employable in various Companies by way of industry internship, industrial training, consultation, project work etc., while Orion employees shall be trained in various field like, power quality improvement, basic electrical safety power management, and soft skill development and to Focus on Research & Development of the Establishment to have innovative technical methods in the production field"
  },
  {
    id: 3,
    subheading: "Future Initiatives & Pans of ORION ACCUMULATORS Pvt Ltd.",
    text: "Our new initiative  Orion Polymer Composite has ventured into the formation polymer related products like Tiles, Interlocks, sheets by converting plastic wastes into Polymer products at the allotted Plots at SIDCO Industrial Park, Moodadi. We are converting plastic wastes both hard and soft in to polymer composite products. The proposed project work is zero discharge Plant and 100% green and eco friendly project and is likely to be fully functional by February 2023, in the name of Orion Polymer Composite, a division of Orion Accumulators Pvt Ltd. <br/> <br/> A proposal for Establishing a Modern and Advanced Battery Manufacturing Unit with Artificial Intelligence system / fully automatic assembling systems in all departments with latest technology, free maintenance, more eco friendly in the Brand name of Orion at a Private Industrial Estate near to Coimbatore is coming up.<br/><br/><br/> An yet another vision is to establish an Institute for study, research and development of power storage solutions in the name ORION for the next generation with an eye on the modern technical advancement of technical features of Power Saving Solutions"
  },
  {
    id: 4,
    subheading: "Concept of All Star",
    text: "Orion ideology is to strive for Customer Delight by providing and making available quality power store products and services, complying with the International norms and social commitments. With this in view we have unleashed our new mission of ORION ALL STORE .It would be one single point counter where all power related products and services are available in line with the customer requirement and expectations under a single roof with no extra charges, to have more Customers Delight and all these at a time in the same spot in a vast, fabulous set up and conducive environment.<br/> <br/> <br/>Environmental concerns are also high on the company's agenda. Waste disposal norms are strictly adhered to while preserving a clean and green ambience in the society. Our motto is development without hurting the environment. We consider it our commitment to the Nature &Society . We are disposing the discarded Batteries so collected as per Batteries (Management & Handling) Rules 2001 through Pollution Control Board approved processing agencies( registered recycler)."
  },
  {
    id: 5,
    subheading: "Corporate Social Responsibility (CSR) ",
    text: "Corporate social responsibility is a broad concept that can take many forms depending on the company. Orion Team, through CSR programs, philanthropy, and volunteer efforts,are contributing their own share which can benefit society as we understand CSR activities can help forge a stronger bond between employeesand feel more connected with the society around them.<br/> <br/> <br/> As a responsible entrepreneur, Chairman Orion Battery is showing keen interest in contributing to the society to help and assist the needy in the form of donation to Charitable societies , Palliative care units and other voluntary Organizations."
  },
  {
    id: 6,
    subheading: "Working Environment at the Company",
    text: "A good work environment is an important competitive advantage. Orion believes that an investment in a good work environment has a positive effect on morale and fosters greater employee commitment. Our approach to working environment is by combining improvements in a material or manufacturing process with improvements in the work environment.We employ several working methods and tools and, by following the work flow, to improve individual job tasks as well as entire production systems.<br/> <br/> <br/>The management has instituted following measures for the betterment of the workers:<br/> <br/> <br/>1.Periodical salary revision of employees, particularly considering the guidelines related to Minimum wages Act/salary.<br/>2.Contribution towards Employees Provident Fund and ESI Scheme as per Govt orders on the subject.<br/>3.Provision of Uniforms.<br/>4.Provision of Safety items like Mask,Gloves,Goggles, Apron, shoes etc as per the working norms requirement.<br/>5.Provision of Messing System to provide Break Fast, Lunch and Evening Tea to the Workers.<br/>6.Planning of holiday tours.<br/>7.Imparting training for which M o U exist with ITI Areacode and AVIT College of Engg ,Chennai..<br/>"
  }
]