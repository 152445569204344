import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const Support = () => {
  const location = useLocation();
  useEffect(() => {
    if(location.pathname.includes('support')){
      window.location.href = 'http://crm.orioncells.com/customer/form'
    }
  }, [location.pathname])
  return (
    <div >
      <iframe src="http://crm.orioncells.com/customer/form" title='crm' style={{border: 0, position:"absolute", top:0, left:0, right:0, bottom:0, width:"100%", height:"100%"}}>
      </iframe>
    </div>
  )
}

export default Support