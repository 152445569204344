import './App.css';
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import Header from './components/Header';
import Products from './pages/Products';
import ContactUs from './pages/ContactUs';
import KnowYourBattery from './pages/KnowYourBattery';
import Waranty from './pages/Waranty';
import Distributors from './pages/Distributors';
import Certification from './pages/Certification';
import Projects from './pages/Projects';
import MeetTheCap from './pages/MeetTheCap';
import MobileHeader from './components/MobileHeader';
import Blog from './pages/Blog';
import Support from './pages/Support';
import { useMediaQuery } from 'react-responsive';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Preloader from './components/Shared/Preloader';

function App() {
  const isMobile = useMediaQuery({ query: "(max-width: 1024px)" });
  const [preloader, setPreloader] = useState(true)
  useEffect(() => {
    setTimeout(() => {
      setPreloader(false)
    }, 3000);
  }, [])

  

  return (
    <div className= {`App ${preloader ? 'overflow-hidden' : ''}`}   >
      {
        preloader ?
        <Preloader /> : null
      }
      <Router>
        {
          isMobile ?
            <MobileHeader /> :
            <Header />
        }
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/aboutUs" element={<AboutUs />} />
          <Route path="/products" element={<Products />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/know-your-battery" element={<KnowYourBattery />} />
          <Route path="/warranty" element={<Waranty />} />
          <Route path="/distributors" element={<Distributors />} />
          <Route path="/certifications" element={<Certification />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/meet-the-captain" element={<MeetTheCap />} />
          <Route path="/support.html" element={<Support />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
