export const Captaindata=[
    {
        id:1,
        name:"Madaparambil Babu",
        father:"Late CHOYI MADAPARAMBIL",
        dob:" 15-01-1963",
        education:"B.Sc (Govt Arts & Science College Calicut) Calicut University  ",
     
        
    }
]
export const data=[{
    id:1,
    subHeading:"Experience :",
    text:"Constituted a modernized Lead Acid Battery Manufacturing Facilities in the year 2002 and established the <br/> brand ORION. Automotive and Tubular Batteries are manufactured and marketed in the Brand Name.<br/>Leading the Institution as a Captain since 2002<b/>"
   },
   {
    id:2,
    subHeading:"Recognitions :",
    text:"Awarded Best Entrepreneur of Kozhikode District (Twice) in the Year 2006-07 and 2010-11 and State Pollution Control Board Award for the Institution for the year 2012. Govt of Kerala has appointed Mr.Babu MP, as the Chairman of Institute Management Committee of ITI Areacode and M/S Orion Battery is the Industry Partner of ITI.Mr.Babu MP is also the Executive Member ( Govt Nominee) of the Governing Body of LBS Centre for Science & Technology , Trivandrum. He has also been awarded Business Excellence Award 2019 in the category of Excellency in Energy Storage Solutions instituted by Media One TV, on 7/11/2019."
   }
]