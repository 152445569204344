import React from 'react'
import '../styles/MobileFooter.scss'

function MobileFooter() {
    return (
        <div className="" style={{ background: '#111' }}>
            <div className="container mx-auto">
                <div className="mob-footer-section">
                    <div className="mob-footer-logo">
                        <img src="/images/logo.png" alt="" />
                    </div>
                    <div className="mob-address">
                        <h3>Address</h3>
                        <p>Orion Battery, Poovattumparamba P.O Peruvayal, Kozhikode, Kerala, 673008</p>
                        <p className='mt-6 '>309/1A3, Puliyamarathupalayam, Varapatti, Coimbatore, Tamil Nadu, 641669</p>
                    </div>
                    <div className="mobfooter-Email">
                        <h5>E-Mail</h5>
                        <p>info@orioncells.com</p>
                    </div>
                    <h3>Follow Us On</h3>
                    <div className="mobsocial-accounts">
                        <div>
                            <img src="/images/insta.png" alt="" />
                        </div>
                        <div>
                            <img src="/images/facebook-icon.png" alt="" />
                        </div>
                        <div>
                            <img src="/images/youtube.png" alt="" />
                        </div>
                        <div>
                            <img src="/images/twitter-icon.png" alt="" />
                        </div>
                    </div>
                    <div className='mob-rights'>
                        <h5>Copyright <span className="orange-text">ORION CELLS</span>, All rights reserved</h5>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MobileFooter