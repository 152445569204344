import React from 'react'
import { useMediaQuery } from 'react-responsive';

const ContactUsHero = () => {
    const isMobile = useMediaQuery({ query: "(max-width: 1024px)" });

    return (
        <>
            {isMobile ? (<div className='pb-10'>
                <div className=' w-full'>
                    <img className='w-full h-full' src={require('../../assets/mobilecontact.png')} alt="" />
                </div>
                <div className='relative flex items-center justify-center w-full sm:top-32 top-12 right-5 sm:right-10'>
                    <img className='object-contain w-[80%]'  alt="" />
                </div>
                <div className='flex items-center justify-center w-full mt-10 flex-col gap-5  '>
                    <h2 className='sm:w-[40%] w-[80%] md:w-[80%] text-center text-white text-[20px]  md:text-5xl font-medium font-argentumSans leading-[30.98px]'>
                    Questions? Comments? Contact Us Anytime!
                    </h2>
                    <p className=' font-ArgentumSans300 font-light text-sm xl:text-[18px] text-white text-center w-[80%]'>Round the clock, covering 360 degree of after sales services. Because Orion believes that a deal with the customer is not an end, but the beginning of a relationship based on trust and reliability. Call our help lines any time: rest assured.</p>
                </div>
            </div>) : (
                <div className='pb-[7%]'>
                    <div className='flex justify-between '>
                        <div className=' w-[45%] '>
                            <img className='w-full h-full' src={require('../../assets/roundcontact.png')} alt="" />
                        </div>
                        <div className='container relative grid grid-cols-8 mx-auto w-[45%]'>
                            <div className='h-full xl:col-span-5 col-span-6 '>
                                <div className='flex  justify-center flex-col w-full h-full gap-5 lg:gap-0 '>
                                    <h2 className='lg:w-[522px] w-[w-100%] lg:h-[145px] text-white lg:text-[40px] text-[25px] font-semibold font-ArgentumSans300 lg:leading-[61.96px]'>Questions? Comments? Contact Us Anytime!</h2>
                                    <p className=' font-ArgentumSans300 font-light text-sm xl:text-[18px] text-white'>Round the clock, covering 360 degree of after sales services. Because Orion believes that a deal with the customer is not an end, but the beginning of a relationship based on trust and reliability. Call our help lines any time: rest assured.</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            )
            }
        </>
    )
}

export default ContactUsHero