
export const blogData = [
  {
    title: 'Powering the Future, Pros & Cons;',
    subTitle: "Sustainable Battery Technology?",
    image: require('../../assets/blog1.webp'),
    date:'21/01/2024',
    paragraph1: "In a world increasingly reliant on portable electronic devices and electric vehicles, the role of batteries in our daily lives cannot be overstated. From smartphones to renewable energy storage solutions, batteries have become an integral part of modern living. However, as the demand for energy storage grows, so does the need for more sustainable battery technology.",
    paragraph2: 'The Battery Challenge',
    paragraph3: "Traditional batteries, especially those based on fossil fuels and heavy metals, have been associated with environmental concerns and finite resources. As a result, there's a pressing need for cleaner, greener, and more sustainable battery technology to reduce our carbon footprint and minimize the environmental impact."
  },
  {
    title: 'Electric Genz Devices & its Future',
    subTitle: 'Solution for Global Warming?',
    image: require('../../assets/blog2.webp'),
    date:"21/01/2024",
    paragraph1: "In a world increasingly reliant on portable electronic devices and electric vehicles, the role of batteries in our daily lives cannot be overstated. From smartphones to renewable energy storage solutions, batteries have become an integral part of modern living. However, as the demand for energy storage grows, so does the need for more sustainable battery technology.",
    paragraph2: 'The Battery Challenge',
    paragraph3: "Traditional batteries, especially those based on fossil fuels and heavy metals, have been associated with environmental concerns and finite resources. As a result, there's a pressing need for cleaner, greener, and more sustainable battery technology to reduce our carbon footprint and minimize the environmental impact."

  }
]